import '../../assets/css/icons.min.css'
import '../../assets/css/app.min.css'
import list_icon from '../../assets/images/List_icon.svg'
import planning from '../../assets/images/planning blck.svg'
import notification from '../../assets/images/notification.svg'
import setting from '../../assets/images/setting.svg'
import chat from '../../assets/images/chat.svg'
import user_main from '../../assets/images/user_main.svg';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useHistory, } from "react-router-dom";
import { ContextData } from "../../components/appsession";
import {Link} from 'react-router-dom'
import { useRef } from 'react'
function AppSubBarRight(props) {
  const {parentCallbackHandler}=props;
  const [userData, setuserData] = useContext(ContextData);
  
  
  const history = useHistory();
  const [pageName, setpageName] = useState("")
  const [disabledUsers, setDisabledUsers] = useState(false)
  const [disabledPlanningProfile, setDisabledPlanningProfile] = useState(false)
  const [disabledRoles, setDisabledRoles] = useState(false)
  const [disabledTerminals, setDisabledTerminals] = useState(false)
  const [disabledDrivers, setDisabledDrivers] = useState(false)
  const [disabledTractors, setDisabledTractors] = useState(false)
  const [disabledTrailers, setDisabledTrailers] = useState(false)
  const [disabledLocations, setDisabledLocations] = useState(false)
  const [disabledCommodityGrs, setDisabledCommodityGrs] = useState(false)
  const [disabledLoadUnload, setDisabledLoadUnload] = useState(false)
  const [disabledWashTypes, setdisabledWashTypes] = useState(false)
  const [disabledWashTypeProcedures, setDisabledWashTypeProcedures] = useState(false)
  const [disabledDriverPayCodesMaster, setDisabledDriverPayCodesMaster] = useState(false)
  const [disabledDriverPayByTerminal, setDisabledDriverPayByTerminal] = useState(false)
 
 
  const containerRef = useRef(null);
 
  useEffect(() => {
(()=>{
  userData && userData?.roles && userData?.roles?.rolespermission && userData?.roles?.rolespermission.map(el => {

    if (el.permissiondetail.code == "Users") {
      setDisabledUsers(el.can_view);
    }

    if (el.permissiondetail.code == "Roles") {
      setDisabledRoles(el.can_view)
    }

    if (el.permissiondetail.code == "Terminals") {

      setDisabledTerminals(el.can_view)
    }

    if (el.permissiondetail.code == "Drivers") {
      setDisabledDrivers(el.can_view)
    }
    if (el.permissiondetail.code == "Trailers") {
      setDisabledTrailers(el.can_view)
    }
    if (el.permissiondetail.code == "Tractors") {
      setDisabledTractors(el.can_view)
    }
    if (el.permissiondetail.code == "CommodityGroups") {
      setDisabledCommodityGrs(el.can_view)
    }

    if (el.permissiondetail.code == "LoadingUnloadingDurations") {
      setDisabledLoadUnload(el.can_view)
    }
    if (el.permissiondetail.code == "Locations") {
      setDisabledLocations(el.can_view)
    }

    if (el.permissiondetail.code == "WashTypes") {
      setdisabledWashTypes(el.can_view)
    }

    if (el.permissiondetail.code == "WashTypeProcedures") {
      setDisabledWashTypeProcedures(el.can_view)
    }
    if (el.permissiondetail.code == "PlanningProfile") {
      setDisabledPlanningProfile(el.can_view)
    }

    if (el.permissiondetail.code == "DriverpayCodesMaster") {
      setDisabledDriverPayCodesMaster(el.can_view)
    }

    if (el.permissiondetail.code == "DriverpayByTerminal") {
      setDisabledDriverPayByTerminal(el.can_view)
    }
  })
})()
   
  }, [userData])
 
  const clickHandler = (e, href) => {
    if (e.metaKey || e.ctrlKey) {
      // Allow normal link behavior if Ctrl or Meta key is pressed
      return;
    }
    e.preventDefault();
    parentCallbackHandler(href)
    history.push(href);
   
  };
  
  
const activeLinkClass = useMemo(() => {
 
  return (href) => {
    return history?.pathname === href ? 'nav-link active' : 'nav-link';
  };
}, [history?.pathname]);


useEffect(() => {
  setpageName(history?.pathname);
}, [history?.pathname]);



  return (
    <div className="sidebar-main-menu" ref={containerRef}>
      <div className="h-100">
        <div className="twocolumn-menu-item d-block">
          <div className="title-box" >
            <h5 className="menu-title">Settings</h5>

            <ul className="nav flex-column sidebar_scroll">
              <div className='menu_heading'>Management </div>
              {disabledUsers && (
                <li className="nav-item cp">
                  <Link
                    to="/users"
                   className={activeLinkClass('/users')}
                   onClick={(e) => clickHandler(e, '/users')}
                  >
                    Users
                  </Link>
                </li>
              )}


              {disabledPlanningProfile &&
                <li className="nav-item cp">
                  <Link  className={activeLinkClass('/planningProfile')}  to='/'  onClick={(e) => clickHandler(e, '/planningProfile')}>
                    Planning Profile
                  </Link>
                </li>
              }
              {disabledRoles && (
                <li className="nav-item cp">
                  <Link
                    to="/roles"
                    className={activeLinkClass('/roles')} 
                    onClick={(e) => clickHandler(e, '/roles')}
                  >
                    Roles and Permissions
                  </Link>
                </li>
              )}
              <div className='menu_heading'>Planning</div>
              {disabledTerminals &&
                <li className="nav-item cp">
                  <Link
                    to="/terminals"
                    className={activeLinkClass('/terminals')} 
                    onClick={(e) => clickHandler(e, '/terminals')}
                  >
                   Terminals
                  </Link>

                </li>
              }
              {disabledDrivers &&
                <li className="nav-item cp">
                  <Link
                    to="/drivers"
                    className={activeLinkClass('/drivers')} 
                    onClick={(e) => clickHandler(e, '/drivers')}
                  >
                    Drivers
                  </Link>

                </li>
              }
              {disabledTractors &&
                <li className="nav-item cp">
                  <Link  
                  className={activeLinkClass('/tractors')}  
                  to='/tractors' 
                  onClick={(e) => clickHandler(e, '/tractors')}
                   >
                    Tractors
                  </Link>
                </li>
              }
              {disabledTrailers &&

                <li className="nav-item cp">
                  <Link  
                  className={activeLinkClass('/trailers')} 
                  to='/trailers' 
                  onClick={(e) => clickHandler(e, '/trailers')}
                   >
                    Trailers
                  </Link>
                </li>
              }
              {disabledLocations &&

                <li className="nav-item cp">
                  <Link  
                  className={activeLinkClass('/locations')}
                   to='/locations'  
                   onClick={(e) => clickHandler(e, '/locations')}
                   >
                    Locations
                  </Link>
                </li>
              }
              {disabledCommodityGrs &&

                <li className="nav-item cp">
                  <Link  className={activeLinkClass('/commoditygroup')}
                   to='/commoditygroup' 
                   onClick={(e) => clickHandler(e, '/commoditygroup')}
                    >
                    Commodity Groups
                  </Link>
                </li>
              }
              {disabledLoadUnload &&
                <li className="nav-item cp">
                  <Link  className={activeLinkClass('/loadunloadduration')} 
                  to='/loadunloaddurations'
                  onClick={(e) => clickHandler(e, '/loadunloaddurations')}
                    >
                    Loading/ Unloading Durations
                  </Link>
                </li>
              }
              <div className='menu_heading'>Tankwash</div>

              {disabledWashTypes &&

                <li className="nav-item cp">
                  <Link className={activeLinkClass('/tankwashcode')} 
                  to='/tankwashcode' 
                  onClick={(e) => clickHandler(e, '/tankwashcode')}
                   >
                    Wash Types
                  </Link>
                </li>
              }
              {disabledWashTypeProcedures &&
                <li className="nav-item cp">
                  <Link className={activeLinkClass('/tankwashprocedure')} to='/tankwashprocedure' 
                  onClick={(e) => clickHandler(e,'/tankwashprocedure')}>
                    Wash Procedures
                  </Link>
                </li>
              }

              <div className='menu_heading'>Driver Pay Settings</div>
              {disabledDriverPayByTerminal &&
                <li className="nav-item cp">
                  <Link  className={activeLinkClass('/driverpayconfiguration')} to='/driverpayconfiguration'  
                   onClick={(e) => clickHandler(e, '/driverpayconfiguration')}>
                    Driver Pay By Terminal
                  </Link>
                </li>
              }
              {disabledDriverPayCodesMaster &&
                <li className="nav-item cp">
                  <Link  className={activeLinkClass('/driverpaycodesmaster')} to='/driverpaycodesmaster'   onClick={(e) => clickHandler(e, '/driverpaycodesmaster')}>
                    Driver Pay Codes Master
                  </Link>
                </li>
              }
              
              {/* <div className='menu_heading'>Alerts</div>

              <li className="nav-item cp">
                <Link  className={activeLinkClass('/new_alert')} to='/new_alert'  onClick={(e) => clickHandler(e, '/new_alert')} >
                  Alerts
                </Link>
              </li> */}



            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppSubBarRight;
