import { pcmilerRouteReports } from "../services/planningBoardService";
import Config from '../Config.json';

export default async (stop1, stop2) => {
  let payload = JSON.stringify({
    ReportRoutes: [
      {
        RouteId: "",
        Stops: [
          {
            Coords: {
              Lat: stop1.latitude,
              Lon: stop1.longitude,
            },
          },
          {
            Coords: {
              Lat: stop2.latitude,
              Lon: stop2.longitude,
            },
          },
        ],
        ReportTypes: [
          {
            __type: `${Config.dev.pcMilerApiType}`,
            THoursWithSeconds: true,
          },
        ],
      },
    ],
  });

  let response = await pcmilerRouteReports(payload);

  let travel_time = response.data[0].ReportLines[1].THours.split(":");
  let duration =
    (parseInt(travel_time[0]) * 60 * 60 +
      parseInt(travel_time[1]) * 60 +
      parseInt(travel_time[2])) *
    1000;
  let distance = response.data[0].ReportLines[1].LMiles;
  let pcmilerduration = response.data[0].ReportLines[1].THours
  return {
    duration,
    distance,
    pcmilerduration
  };
}
