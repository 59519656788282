import { useState, useEffect, useContext } from 'react';
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import Skeleton from '@mui/material/Skeleton';
import Stack from "@mui/material/Stack";
import { getTerminalbylatlong } from "../../services/planningBoardService";
import { BaseConfig } from "../.././baseConfig";
import { createReturn, getNearByLocation } from '../../services/planningBoardService';
import { DropContext } from '../../pages/planner/planner';
import WarningModal from '../warningModal'; 
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { CircularProgress } from '@mui/material';
import { DateTime } from "luxon";
import errorMessageDisplay from '../../utils/errorMessageDisplay';

const TrimbleMapsForReturn = (props) => {
    // Props
    const { showReturnModal, setShowReturnModal, selectedDriverData, selectedMovementData, setShowReturnBtn, driverCurrentLocation, errorMessageDisplay, setWarningModalOpen,handleLoadingBarOnDriver,returnActualArrival,setReturnActualArrival,selectedTimezoneDate,selectedPlanningProfile } = props;

    // Context
    const context = useContext(DropContext);

    const miles = [10, 15, 20];
    // States

    const [isLocationLoading, setIsLocationLoading] = useState(false);
    const [searchLocationData, setSearchLocationData] = useState("");
    const [selectedLocationLatitude, setSelectedLocationLatitude] = useState("");
    const [selectedLocationLongitude, setSelectedLocationLongitude] = useState("");
    const [locationsDataFromMap, setLocationsDataFromMap] = useState([]);
    const [selectedDistance, setSelectedDistance] = useState(5);
    const [mapNumber, setMapNumber] = useState({ ...showReturnModal, number: showReturnModal?.movOrStop === "mov" ? 1 : 2 });
    const [returnFromLocation, setReturnFromLocation] = useState({});
    const [returnToLocation, setReturnToLocation] = useState({});
    const [bdComment, setBdComment] = useState("");
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [planId, setPlanId] = useState("");
    const [loadedMovement, setLoadedMovement] = useState({});
    const [actualArrival,setActualArrival] = useState(showReturnModal?.movOrStop=="stop" ? returnActualArrival : DateTime.now().setZone(selectedPlanningProfile?.terminal.timezone));
    const [actualDepart,setActualDepart] = useState(DateTime.now().setZone(selectedPlanningProfile?.terminal.timezone));
    const [returnConfirmBtn,setReturnConfirmBtn] = useState(false)
    const [showValueOfActualsArrive, setShowValueOfActualsArrive] = useState(showReturnModal?.movOrStop=="stop")
    const [showValueOfActualsDepart, setShowValueOfActualsDepart] = useState(false)
 
    const filterLocationByDist = async (e) => {
        const value = Number(e.target.value);
        setSelectedDistance(value);
        try {
            if (selectedLocationLongitude && selectedLocationLatitude) {
                setIsLocationLoading(true);
                let payload = {
                    longitude: selectedLocationLongitude,
                    latitude: selectedLocationLatitude,
                    searchtext: searchLocationData,
                    distance: value
                };

                const latlanglocation = await getTerminalbylatlong(payload);

                setLocationsDataFromMap(latlanglocation);
                setIsLocationLoading(false);
            }
        } catch(err){
            if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
              errorMessageDisplay(err?.response?.data?.errors);
            }else{
            toast.error('There is a problem in retrieving the location data, Please Refresh Your Screen', { autoClose: false } );
            }
          }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            searchbylatlong(e);
        }
    };

    const handleSearchInput = (e) => {
        setSearchLocationData(e.target.value);
    };

    const searchbylatlong = async (e, searchText) => {
        e.preventDefault();
        try {
            if (searchText && selectedLocationLongitude && selectedLocationLatitude) {
                setIsLocationLoading(true);
                let payload = {
                    longitude: selectedLocationLongitude,
                    latitude: selectedLocationLatitude,
                    searchtext: searchText,
                    distance: selectedDistance
                };
                const latlanglocation = await getTerminalbylatlong(payload);
                setLocationsDataFromMap(latlanglocation);
                setIsLocationLoading(false);

            } else if (selectedLocationLongitude && selectedLocationLatitude) {
                setIsLocationLoading(true);
                let payload = {
                    longitude: selectedLocationLongitude,
                    latitude: selectedLocationLatitude,
                    searchtext: searchLocationData,
                    distance: selectedDistance
                };
                const latlanglocation = await getTerminalbylatlong(payload);
                setLocationsDataFromMap(latlanglocation);
                setIsLocationLoading(false);
            }

        } catch(err){
            if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
              errorMessageDisplay(err?.response?.data?.errors);
            }else{
            toast.error('Something Went Wrong in retrieving the location data, Please Refresh Your Screen', { autoClose: false } );
            }
          }
    };

    // Change trailer dropoff location
    const selectButtonHandler = async (location) => {
        // Here I need to add this location if it is not in our database.
        try {
            if (location?.label == 'nearbysearch') {
                if (mapNumber.number === 1) {
                    setReturnFromLocation(location);
                    setMapNumber({ ...showReturnModal, number: 2 });
                    setLocationsDataFromMap([]);


                } else if (mapNumber.number === 2) {
                    if (mapNumber?.movOrStop === "stop") {
                        setReturnFromLocation({ latituide: loadedMovement?.movementData?.delivery?.latitude, longitude: loadedMovement?.movementData?.delivery?.longitude });
                    }
                    setReturnToLocation(location);
                    setMapNumber({ ...showReturnModal, isVisible: false });
                    setShowCommentModal(true);

                }
            } else {
                if (mapNumber.number === 1) {
                    setReturnFromLocation({ latituide: location?.latitude, longitude: location?.longitude, location_id: location?.code });
                    setMapNumber({ ...showReturnModal, number: 2 });
                    setLocationsDataFromMap([]);


                } else if (mapNumber.number === 2) {
                    if (mapNumber?.movOrStop === "stop") {
                        setReturnFromLocation({ latituide: loadedMovement?.movementData?.delivery?.latitude, longitude: loadedMovement?.movementData?.delivery?.longitude, location_id: location?.code });
                    }

                    setReturnToLocation({ latituide: location?.latitude, longitude: location?.longitude, location_id: location?.code });
                    setMapNumber({ ...showReturnModal, isVisible: false });
                    setShowCommentModal(true);
                }
            }
        } catch(err){
            if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
              errorMessageDisplay(err?.response?.data?.errors);
            }else{
            toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false } );
            }
          }
    };

    // For closing trailer map modal
    const closeMapModal = () => {
        setShowReturnModal({ isVisible: false, stopsOrMov: "" });
        setShowReturnBtn({ isVisible: false, stopsOrMov: "" });
    };

    const handleCommentClose = () => {
        handleLoadingBarOnDriver(selectedDriverData.driverId, false);
        setShowReturnModal({ isVisible: false, stopsOrMov: "" });
        setShowCommentModal(false);
        setShowReturnBtn({ isVisible: false, stopsOrMov: "" });
        setActualArrival(null);
        setReturnActualArrival(null)
        setActualDepart(null)
    };


    const nextBtnHandler = () => {
        setMapNumber({ ...showReturnModal, number: 2 });
    };

    let pickupLocation = {};
    let deliveryLocation = {};


    useEffect(() => {
        // There will be more condtion because, I need to show different maps for movement and stop
        if (mapNumber?.isVisible) {
            (async () => {
                TrimbleMaps.APIKey = BaseConfig.Mapkey;
                // await findDriverLocation(selectedDriverData);
                setPlanId(selectedMovementData.movementData.delivery.plan_id);

                if (mapNumber?.isVisible && mapNumber?.movOrStop === "mov") {
                    setLoadedMovement(selectedMovementData);
                    pickupLocation = selectedMovementData.movementData.pickup;
                    deliveryLocation = selectedMovementData.movementData.delivery;
                } else {
                    const emptyMovement = selectedMovementData.movementData; // Empty M

                    const loadedMov = selectedDriverData.movements.find((mov) => mov.stopData === null && mov.movementData.delivery.lm_stop_id === emptyMovement.pickup.lm_stop_id);

                    setLoadedMovement(loadedMov);

                    pickupLocation = loadedMov.movementData.pickup; // PU or SP
                    deliveryLocation = emptyMovement.pickup; // DEL or SD
                }

                const map = new TrimbleMaps.Map({
                    container: "map",
                    style: TrimbleMaps.Common.Style.TRANSPORTATION,
                    center: new TrimbleMaps.LngLat(
                        pickupLocation?.longitude,
                        pickupLocation?.latitude
                    ),
                    zoom: 12,
                });

                const myRoute = new TrimbleMaps.Route({
                    container: "map",
                    routeId: "myRoute",
                    style: TrimbleMaps.Common.Style.TRANSPORTATION,
                    stops: [
                        new TrimbleMaps.LngLat(
                            pickupLocation?.longitude,
                            pickupLocation?.latitude
                        ),
                        new TrimbleMaps.LngLat(
                            deliveryLocation?.longitude,
                            deliveryLocation?.latitude
                        ),
                    ],
                });

                // Driver exact location
                const marker = new TrimbleMaps.Marker({
                    draggable: true,
                }).setLngLat([
                    driverCurrentLocation.length > 0 ? driverCurrentLocation[0].longitude : deliveryLocation?.longitude,
                    driverCurrentLocation.length > 0 ? driverCurrentLocation[0].latitude : deliveryLocation?.latitude
                ]).addTo(map);

                map.addControl(new TrimbleMaps.NavigationControl());
                const scale = new TrimbleMaps.ScaleControl({
                    maxWidth: 80,
                    unit: "imperial",
                });

                map.on("load", function () {
                    myRoute.addTo(map);
                });

                map.addControl(scale);               

                marker.on("dragend", async function (e) {
                    const lngLat = e.target.getLngLat();
                    setSelectedLocationLongitude(lngLat.lng.toFixed(4));
                    setSelectedLocationLatitude(lngLat.lat.toFixed(4));

                    let payload = {
                        longitude: lngLat.lng.toFixed(4),
                        latitude: lngLat.lat.toFixed(4),
                        searchtext: searchLocationData,
                        distance: selectedDistance,
                    };

                    let payLoadTrimblemap = {
                        longitude: lngLat.lng.toFixed(4),
                        latitude: lngLat.lat.toFixed(4),
                        radius: selectedDistance
                    }

                    try {
                        setIsLocationLoading(true);
                        const allLocationsBasedOnLatLong = await Promise.all([getTerminalbylatlong(payload), getNearByLocation(payLoadTrimblemap)]);

                        let locationfromtrimblemap = [];
                        let nearBylocations;
                        let locationfromlocationTable = allLocationsBasedOnLatLong[0]; // Internal API results


                        if (allLocationsBasedOnLatLong[1]?.data?.status == 'success' && allLocationsBasedOnLatLong[1]?.data?.data?.length > 0) {
                            allLocationsBasedOnLatLong[1]?.data?.data?.forEach((item) => {
                                let obj = {};
                                obj.distance = Number(item?.DistanceFromCenter?.Value)?.toFixed(2);
                                obj.address1 = item?.POILocation?.Address?.StreetAddress;
                                obj.city = item?.POILocation?.Address?.City;
                                obj.code = null;
                                obj.latitude = item?.POILocation?.Coords?.Lat;
                                obj.longitude = item?.POILocation?.Coords?.Lon;
                                obj.location_name = item?.POILocation?.PlaceName;
                                obj.state = item?.POILocation?.Address?.State;
                                obj.zip = item?.POILocation?.Address?.Zip;
                                obj.label = "nearbysearch";

                                if (obj.location_name && obj.address1 && obj.city) {
                                    if (allLocationsBasedOnLatLong[0].length > 0 && Object.keys(allLocationsBasedOnLatLong[0]?.find(({ location_name }) => location_name == obj.location_name) ?? {})?.length == 0) {
                                        locationfromtrimblemap.push(obj);
                                    } else if (allLocationsBasedOnLatLong[0].length == 0) {
                                        locationfromtrimblemap.push(obj);
                                    }
                                }
                            })
                        }
                        if (locationfromlocationTable.length > 0 && locationfromtrimblemap.length > 0) {
                            nearBylocations = [...locationfromlocationTable, ...locationfromtrimblemap]?.sort((a, b) => Number(a.distance) - Number(b.distance))
                        } else if (locationfromlocationTable.length > 0) {
                            nearBylocations = [...locationfromlocationTable];
                        } else if (locationfromtrimblemap.length > 0) {
                            nearBylocations = [...locationfromtrimblemap]
                        } else {
                            nearBylocations = []
                        }
                        setIsLocationLoading(false);
                        setLocationsDataFromMap(nearBylocations);
                    } catch(err){
                        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
                          errorMessageDisplay(err?.response?.data?.errors);
                        }else{
                        toast.error('Something Went Wrong in retrieving the location data, Please Refresh Your Screen', { autoClose: false } );
                        }
                      }
                });
            })();

        }

    }, [mapNumber?.isVisible, mapNumber?.number, driverCurrentLocation]);

   const handleActualArriveDateChange=(date)=>{
            setActualArrival(date);
            setShowValueOfActualsArrive(true)
    }
    const handleActualDepartDateChange=(date)=>{
            setActualDepart(date)
            setShowValueOfActualsDepart(true)
    }

    const createReurnHandler = async (payloadWarningModal,warningDropdownValue) => {
        try {
            setReturnConfirmBtn(true);
            handleLoadingBarOnDriver(selectedDriverData.driverId, true);

            let allmovementoncurrentdate =selectedDriverData?.movements?.filter((mvts) => mvts.startTime > selectedTimezoneDate && mvts.stopData == null)
            let previousStopInfo = allmovementoncurrentdate?.filter((mvts) => mvts.movementData.delivery.lm_stop_id == selectedMovementData?.movementData?.pickup?.lm_stop_id);
            let previousStopData= showReturnModal?.movOrStop=="stop" ? previousStopInfo[0]?.movementData.pickup.actual_departure_utc : previousStopInfo[0]?.movementData.delivery.actual_departure_utc
            if (previousStopData) {
                let actualInMillies =actualArrival?.toMillis();
                if (actualArrival && showValueOfActualsArrive  && previousStopData > actualInMillies) {
                    setReturnConfirmBtn(false);
                  return toast.error("Actual arrival must be greater then previous stops depart", { autoClose: false });
                }
              }

              if (actualArrival && actualDepart  &&  showValueOfActualsArrive && showValueOfActualsDepart && actualDepart <  actualArrival) {
                setReturnConfirmBtn(false);
                return toast.error("Actual depart should be greater than actaul arrival", { autoClose: false });
              } else if (actualArrival == null  && showValueOfActualsDepart && actualDepart !== null) {
                setReturnConfirmBtn(false);
                return toast.error("Actual arrival need to be filled before saving actual depart", { autoClose: false });
              }
            
            const payload = {
                movement_id: loadedMovement.movementData.load_id,           
                return_to_location: returnToLocation,
                bd_comments: bdComment,
                actual_arrival_datetime:actualArrival?.toISO(),
                actual_departure_datetime:actualDepart?.toISO()
            };

            if (showReturnModal?.movOrStop === "mov") {
                payload['return_location'] = returnFromLocation;
            }

            if (warningDropdownValue) {
                payload['option'] = payloadWarningModal?.option?.length > 0 ? [...payloadWarningModal?.option, warningDropdownValue] : [warningDropdownValue]
              }

            if (payload.bd_comments.length === 0 || !showValueOfActualsArrive || !showValueOfActualsDepart) {
                toast.error("Please fill all details.");
                setReturnConfirmBtn(false);
                return;
            }

            const response = await createReturn(payload, planId);

            if (response?.data?.status == 'warning') {
                setReturnConfirmBtn(false)
                let str = response?.data?.errors?.map((el) => el?.message)?.join(",") ?? "";
                setWarningModalOpen(() => {
                    return (
                        <div>
                            <WarningModal
                                setWarningModalOpen={setWarningModalOpen}
                                warningMasterData={response?.data?.options ?? []}
                                parentCallback={createReurnHandler}
                                errormssg={str}
                                payload={payload}
                            />
                        </div>
                    )
                });
                return;
            } else if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
                setReturnConfirmBtn(false)
                errorMessageDisplay(response?.data?.errors);
                handleLoadingBarOnDriver(selectedDriverData.driverId, false);
            } else {
                setShowCommentModal(false);
                setShowReturnModal({ isVisible: false, movOrStop: "" });
                setShowReturnBtn({ isVisible: false, stopsOrMov: "" });
                setReturnConfirmBtn(false)
                setActualArrival(null)
                setReturnActualArrival(null)
                setActualDepart(null);
                toast.success("Return created successfully.");
                context.refreshDataFromAPI(context.payloadForRefreshData);
            }

        } catch (err) {
               setReturnConfirmBtn(false)
                setActualArrival(null)
                setReturnActualArrival(null)
                setActualDepart(null)
                handleLoadingBarOnDriver(selectedDriverData.driverId, false);
                    if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
                      errorMessageDisplay(err?.response?.data?.errors);
                    }else{
                    toast.error('Something Went Wrong While Return, Please Refresh Your Screen', { autoClose: false } );
                    }
                  }
    };

    return (
        <>
            {/* Two modal will be ope */}
            <Modal
                show={mapNumber?.isVisible}
                onHide={() => closeMapModal()}
                size='lg'
                dialogClassName="modal-width"
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>
                        <p className='tank_mapview_header'>{`Where is the driver returning ${mapNumber?.isVisible && mapNumber?.number === 1 ? "from" : "to"}`}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='split_view_container'>
                        <div className='split_view_left'>
                            <div className='search_section'>
                                <div className='search_field_tankwash'>
                                    <form class='example'>
                                        <input
                                            type='text'
                                            placeholder='Search..'
                                            name='search2'
                                            onChange={(e) => handleSearchInput(e)}
                                            onKeyUp={handleKeyPress}
                                        />
                                        <button onClick={(e) => searchbylatlong(e, searchLocationData)}>
                                            <i class='fa fa-search'></i>
                                        </button>
                                    </form>
                                </div>
                                <div className='split_popup_dropdown'>
                                    <form>
                                        <select
                                            name='cars'
                                            id='cars'
                                            value={selectedDistance}
                                            onChange={(e) => filterLocationByDist(e)}
                                        >
                                            <option value='5'>
                                                5
                                            </option>
                                            {miles.map((mile, k) => (
                                                <option key={k}>{mile}</option>
                                            ))}
                                        </select>
                                    </form>
                                </div>
                            </div>
                            <p className='nearby_text'>
                                Nearby Locations:{locationsDataFromMap.length}
                            </p>
                            <div className='location_section_wrapper'>
                                {isLocationLoading ? (
                                    <Stack spacing={1}>
                                        <div className='loader_skeleton'>
                                            <Skeleton variant='rectangular' />
                                        </div>
                                    </Stack>
                                ) : (
                                    <></>
                                )}
                                {locationsDataFromMap.map((location, index) => (
                                    <div className="location_section">
                                        <div className='location_section_left'>
                                            <div className="split_nearby_location_container">
                                                <div>
                                                    <div className='location_name'>
                                                        {`${location?.code ? location?.code + ' -' : ''}`} {location?.location_name}
                                                        <br />
                                                        {location.address1}
                                                        <br />
                                                        {location.city}, {location.state}, {location.zip}
                                                    </div>
                                                    <div className='location_name'>
                                                        Distance: {location.distance} miles
                                                    </div>
                                                    <div className='location_section_right'></div>
                                                </div>
                                                <button
                                                    type='button'
                                                    class='btn-blue_schedule split_button'
                                                    onClick={(i) =>
                                                        selectButtonHandler(location, index)
                                                    }
                                                >
                                                    Select
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='split_view_right_map'>
                            <div id='map' className="split_view_right_map__map" style={{ height: "600px", width: "100%" }}>
                            </div>
                        </div>
                        <div className="split_view_right_map__guide">
                            <p>To do a return on the movement, drag the red pin and drop where you would like the return along the route. Then pick a location from the list for return.</p>
                            <div>
                                <div className="split_pickup_location" style={{ marginTop: "50px" }}>
                                    <div className="split_pickup_location_icon"></div>
                                    <div>
                                        {loadedMovement?.movementData?.pickup?.stop_type === 'SP'
                                            ? 'Split Pickup'
                                            : loadedMovement?.movementData?.pickup?.stop_type === 'RET'
                                                ? 'Return'
                                                : 'Pickup'}
                                    </div>

                                     <Button
                                     type='button'
                                     onClick={(i) =>
                                         selectButtonHandler(loadedMovement?.movementData?.pickup?.location)
                                     }
                                    >Return</Button>
                                </div>
                                <div className='location_name ml_20 split_pickup_delivery_label'>
                                    {loadedMovement?.movementData?.pickup?.lm_location_id}-{loadedMovement?.movementData?.pickup?.lm_location_name}
                                    <br />
                                    {loadedMovement?.movementData?.pickup?.address}
                                    <br />
                                    {loadedMovement?.movementData?.pickup?.city}, {loadedMovement?.movementData?.pickup?.state}, {loadedMovement?.movementData?.pickup?.zip}
                                </div>
                                <div className="split_pickup_location">
                                    <div className="split_delivery_location_icon"></div>
                                    <div>Delivery</div>
                                </div>
                                <div className='location_name ml_20 split_pickup_delivery_label'>
                                    {loadedMovement?.movementData?.delivery?.lm_location_id}-{loadedMovement?.movementData?.delivery?.lm_location_name}
                                    <br />
                                    {loadedMovement?.movementData?.delivery?.address}
                                    <br />
                                    {loadedMovement?.movementData?.delivery?.city}, {loadedMovement?.movementData?.delivery?.state}, {loadedMovement?.movementData?.delivery?.zip}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        mapNumber?.isVisible && mapNumber?.number === 1 ? (
                            <Button
                                disabled={Object.keys(returnFromLocation).length <= 0}
                                onClick={(e) => nextBtnHandler()}
                            >
                                Next
                            </Button>
                        ) : (
                            <Button
                                onClick={(e) => closeMapModal()}
                            >
                                Close
                            </Button>
                        )
                    }
                </Modal.Footer>
            </Modal>

            {/* Comment Modal */}
            <Modal
                show={showCommentModal}
                onHide={() => handleCommentClose()}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" />
                </Modal.Header>
                <Modal.Body>
                    <>
                        <label for="typeHeadCommodityGroup">
                            Actual Arrival *
                        </label>
                        <div className="meterial_autocomplete">
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DateTimePicker
                                    slotProps={{
                                        textField: {
                                            error: false,
                                            fullWidth: true,
                                            value: showValueOfActualsArrive ? actualArrival : null
                                        },
                                        actionBar: { actions: ['clear', "accept"] }
                                    }}
                                    orientation="landscape"
                                    value={actualArrival}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                    onChange={handleActualArriveDateChange}
                                />
                            </LocalizationProvider>
                        </div>
                        <label for="typeHeadCommodityGroup">
                            Actual Depart *
                        </label>
                        <div className="meterial_autocomplete">
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <>
                                    <DateTimePicker
                                        slotProps={{
                                            textField: {
                                                error: false,
                                                fullWidth: true,
                                                value: showValueOfActualsDepart ? actualDepart : null
                                            },
                                            actionBar: { actions: ['clear', "accept"] }
                                        }}
                                        orientation="landscape"
                                        value={actualDepart}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                        onChange={handleActualDepartDateChange}
                                    />
                                </>
                            </LocalizationProvider>
                        </div>
                        <div className="tankwash_reason_code">
                            <label for="comments" className="override-date-container__secondary-text">Comments *</label>
                            <textarea
                                id="comments"
                                name="comments"
                                placeholder="Write comments..."
                                rows="10"
                                cols="49"
                                resi
                                onChange={(e) => setBdComment(e.target.value)}
                            />
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    {
                        !returnConfirmBtn ?
                            <Button
                                onClick={() =>
                                    createReurnHandler()
                                }
                            >
                                Submit
                            </Button> :
                            <div className="spinner_modal_footer_md">
                                <CircularProgress />
                            </div>
                    }
                    <Button onClick={() => handleCommentClose()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TrimbleMapsForReturn;