import { useCallback,useContext, useEffect, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import ProgressBar from "react-bootstrap/ProgressBar";
import TextField from "@mui/material/TextField";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { DateTime } from "luxon";
import { Autocomplete } from "@mui/lab";
import { ContextData } from "../../components/appsession";
import { ToastContainer, toast } from "react-toastify";
import {getActivelocations} from "../../services/locationService";
import { getallactivedriverspaybase } from "../../services/driverPayCodesMasterService.js";
import { UpdateDriverPayByDistanceExceptionLaneOverride,AddDriverPayByDistanceExceptionLaneOverride,searchDriverpayKendo } from "../../services/driverPayServer";
import { getAllActiveDrivers, getAllDrivers } from "../../services/driverService.js";
import filterOperators from '../../utils/filterOperators.js'
import debounce from 'lodash/debounce';
import AuditLogModalComponent from "./auditLogModal";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import errorMessageDisplay from "../../utils/errorMessageDisplay.js";

const DriverPayOverrideTable = (props) => {
  const { accessDisabled,isEditDisable } = props;
  const [data, setData] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [allField,setallField]=useState({
    matchMethod:"",
    origin:"",
    destination:"",
    payCode:"",
    payRate:"",
    effectiveDate:null,
    expirationDate:null,
    includedDriver:[]
  })
  const[selectedId,setSelectedId]=useState("");
  const [userData, setUserData] = useContext(ContextData);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const[masterData,setMasterData]=useState({orgDestcode:[],payCode:[],matchMethod:['O','M'],drivers:[]})

  const [takeNumberOfResult, setTakeNumberOfResult] = useState(25);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [searchTerms, setSearchTerms] = useState([]);
  const [sortData,setSortData] = useState([{field: "id",dir: "asc"}]);

  const [dataState, setDataState] = useState({
    skip: skipNumberOfResult,
    take: takeNumberOfResult,
    filter: {
      logic: "and",
      filters: [],
    },
    sort:sortData,
  });
  const [dataResult, setDataResult] = useState([]);

  
  const fieldsToField = [
    "id",
    "match_method",
    "origin_location_fullname",
    "destination_location_fullname",
    "driver_pay_code",
    "amount",
    "included_drivers",
];

const fieldsToUpdate = [
  "effective_date",
  "expiry_date",
];

  useEffect(async () => {
    let resAll=await Promise.all([getActivelocations(),getallactivedriverspaybase(),getAllActiveDrivers()])
    let  orgDestCodeArr=resAll[0]?.length>0 && resAll[0]?.map((el)=>`${el.code} - ${el.name}`);
    let payCodeArr=resAll[1]?.length>0 && resAll[1]?.map((el)=>`${el.pay_code.trim()}-${el.pay_description.trim()}`);
    let driverArr=resAll[2]?.length>0 && resAll[2]?.map((el)=>`${(el.driver_id).trim()}-${(el.driver_name).trim()}`)
   let obj={...masterData}
   obj.orgDestcode=[...new Set(orgDestCodeArr)].sort();
   obj.payCode=[...new Set(payCodeArr)].sort();
   obj.drivers=[...new Set(driverArr)].sort();
   setMasterData(obj)
  }, [])

  useEffect(() => {
    setDataResult(data);
  }, [data]);

  const dataStateChange = (event) => {
    const filters = event?.dataState?.filter?.filters || [];
    const sortTerms=event?.dataState?.sort || [];
    const terms = filters && filters.length > 0 ? filters.map(({ field, value, operator }) => ({ field, value, operator })) : [];
    const sorts= sortTerms && sortTerms.length>0 ? sortTerms.map(({ field, dir })=>({ field, dir })) : [];
    setSearchTerms(terms);
    setSortData(sorts);
    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    setDataState(event.dataState);
  };


 // this useeffect will run on adding something in filter on removing in filter
 useEffect(() => {
  // it will run when there is no filter in kendo
  if (searchTerms.length <= 0 && sortData.length<=0) {
      let newsearchTerms = [];
      let newSort=[];
      debouncedSearch(newsearchTerms, newSort, pageNumber, takeNumberOfResult);
  }else  if (searchTerms.length <= 0 && sortData.length>0) {
    let newsearchTerms = [];
    debouncedSearch(newsearchTerms, sortData, pageNumber, takeNumberOfResult);
}

  // it will run when there is filter in kendo
  if (searchTerms.length > 0) {

      let searchTerm;
      // remove null undefined values of fields
      searchTerm = searchTerms.filter(obj => {
          if (
              fieldsToField.includes(obj.field) &&
              (obj.value === null || obj.value === undefined || obj.value === "")
          ) {
              return false; // remove the object
          }
          return true; // keep the object
      });

      searchTerm = searchTerm.filter(obj => {
        if (
            fieldsToUpdate.includes(obj.field) &&
            (obj.value === null || obj.value === undefined || obj.value === "")
        ) {
            return false; // remove the object
        }
        return true; // keep the object
    });
      
      setDataState({
          skip: skipNumberOfResult,
          take: takeNumberOfResult,
          filter: {
              logic: "and",
              filters: searchTerm
          },
          sort: sortData,
      })
      debouncedSearch(searchTerm, sortData, pageNumber, takeNumberOfResult);
  }

}, [searchTerms, pageNumber, takeNumberOfResult,sortData]);

const debouncedSearch = useCallback(
  debounce(async (searchTerms, sortData, page, size) => {
       let arr=searchTerms?.map((el)=>{
        let obj={};
        obj[el?.field]=el?.value
        obj.operator=el?.operator;
        return obj
       })
       let tableName="vw_driver_pay_base_config"
       const search = await searchDriverpayKendo(tableName,arr, sortData, page, size,);
      if (search.data.length <= 0) {
          setData(search.data);
          setTotalResult(Number(search?.count[0]?.count));
      }
      if (search.data.length > 0) {
          setData(search.data);
      }
      setTotalResult(Number(search?.count[0]?.count));
  }, 1000),
  []
);


  const rowClickHandler=(item)=>{
    let obj={...allField}
  obj.matchMethod=(item?.match_method);
  masterData?.orgDestcode?.forEach((el)=>{
    if(el?.split("-")[0]?.trim() === item?.origin){
      obj.origin=el;
    }else if(el?.split("-")[0]?.trim() === item?.destination){
      obj.destination=el;
    }
  })
  masterData?.payCode?.forEach((el)=>{
    if(el?.split("-")[0]?.trim() === item?.pay_code){     
      obj.payCode=el;
    }
  })
  let includeDriverArr=[];
  masterData?.drivers?.forEach((el)=>{
    if(item?.included_drivers?.split(",").find((ele)=>ele?.trim() === el?.split("-")[0]?.trim())){
      includeDriverArr.push(el)
    }
  })

  obj.payRate=(item.amount);
  obj.effectiveDate=(DateTime.fromISO(item.effective_date) || "");
  obj.expirationDate=(DateTime.fromISO(item.expiry_date) || "");
  obj.includedDriver=includeDriverArr
  setallField(obj)
  setSelectedId(item.id);
  setIsEditModalOpen(true)
  }

  const clearStateValues = () => {
   setallField({
    matchMethod:"",
    origin:"",
    destination:"",
    payCode:"",
    payRate:"",
    effectiveDate:null,
    expirationDate:null,
    includedDriver:""
   })
  }

  const handleEditClose = () => {
    setIsEditModalOpen(false);
    clearStateValues();
  };
  const handleAddClose=()=>{
    setIsAddModalOpen(false);
    clearStateValues();
  }

  const openAddModal=()=>{
  clearStateValues();
  setIsAddModalOpen(true);
  }
   // this function will run when we edit fields
 const editDriverPayConfigHandler=async()=>{
   const payload={ 
    match_method:allField?.matchMethod,
    origin:allField?.origin?.split("-")[0].trim() ,
    destination :allField?.destination?.split("-")[0].trim(),
    pay_code:allField?.payCode.split("-")[0].trim(),
    amount :Number(allField?.payRate),
    effective_date:allField?.effectiveDate?.toFormat('yyyy-LL-dd')
    // updated_by: userData?.email.split('@')[0]  
  }
  if(allField?.includedDriver.length>0){
     payload.included_drivers=allField?.includedDriver?.map((el)=>el.split("-")[0]).join(",")
   }

   if(allField?.expirationDate){
    if(DateTime.fromISO(allField?.expirationDate).toMillis()>DateTime.fromISO(allField?.effectiveDate).toMillis()){
    payload.expiry_date = allField?.expirationDate?.toFormat('yyyy-LL-dd')
    }else{
      return toast.error("Expiration Date Should Be Greater Than Effective Date", "Error");
    } 
   }

   if (Object.values(payload).every((key) => key !== '') ? true : false) {
    try {
      let tabname = "driver_pay_lane_override"
      const response = await UpdateDriverPayByDistanceExceptionLaneOverride(tabname,payload,selectedId);
      if (response.status === 200) {
        toast.success("Driver pay Lane Override has been updated successfully.", "Success");
        setIsEditModalOpen(false);
        clearStateValues();
        handleRefresh();
      } else {
        toast.error("Something went wrong, please try again later.", "Error");
      }
    } catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false } );
      }
    }
  } else {
    toast.error("Please fill all fields.", "Error");
  }
 }

 // this function will run when we add
 const AdddriverpayconfigHandler=async()=>{
  const payload={
    company_id:'TMS',
    match_method:allField?.matchMethod,
    origin:allField?.origin?.split("-")[0].trim(),
    destination :allField?.destination?.split("-")[0].trim(),
    pay_code:allField?.payCode.split("-")[0].trim(),
    amount :Number(allField?.payRate),
    effective_date:allField?.effectiveDate?.toFormat('yyyy-LL-dd'),
   }

   if(allField?.expirationDate){
    if(DateTime.fromISO(allField?.expirationDate).toMillis()>DateTime.fromISO(allField?.effectiveDate).toMillis()){
    payload.expiry_date = allField?.expirationDate?.toFormat('yyyy-LL-dd')
    }else{
      return toast.error("Expiration Date Should Be Greater Than Effective Date", "Error");
    } 
   }
   if(allField?.includedDriver.length>0){
    payload.included_drivers=allField?.includedDriver?.map((el)=>el.split("-")[0]).join(",")
   }
 
   if (Object.values(payload).every((key) => key !== '') ? true : false  ) {
    try {
     let tabname = "driver_pay_lane_override"
      const response = await AddDriverPayByDistanceExceptionLaneOverride(tabname,payload);
      if (response.status === 200) {
        toast.success("Driver pay Lane Override has been created successfully.", "Success");
        setIsAddModalOpen(false);
        clearStateValues();
        handleRefresh();
      } else {
        toast.error("Something went wrong, please try again later.", "Error");
      }
    }catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else if(err?.response?.data?.message?.includes("duplicate")){
        return toast.error("Duplicate Data Cannot Be Added", "Error");
      }else{
      toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false } );
      }
    }
    }else {
      toast.error("Please fill all fields.", "Error");
    }
 }
 const handleChange=(value,name)=>{
  setallField({...allField,[name]:value})
   }

   const convertdate = (duration) => {
    if(duration){
      return DateTime.fromISO(duration).toFormat("MM/dd/yyyy").toString();
    }else{
      return "No Data";
    }
  };
  const auditLogComponentRef = useRef();

  // Audit log modal open handler
  const auditLogModalOpenHandler = (props) => {
    // Access the child component's function using the ref
    auditLogComponentRef.current.fetchAllAuditLogData(props.id, "driver_pay_lane_override");
  };

    // when we edit or add this function will fetch all latest data
 const handleRefresh=()=>{
    debouncedSearch(dataState?.filter?.filters ?? [], dataState.sort ?? [],  pageNumber, takeNumberOfResult);
   }

  return (
    <>
    <ToastContainer
        position='top-right'
        theme='colored'
        autoClose={1000}
      />
    <div className='row special_row_flex'>
      <div className='col-xl-12'>
        <div className='card card_shadow'>
          <div className='card-body'>
          <div className='row'>
                <div className='col-md-5 textadjust_terminal'>
                </div>
                <div className='col-md-7 textadjust_terminaladd_button '>
                  <div className='add_icon'>
                    <button
                      type='button'
                      className='btn_blue btn-blue mr_10 mb-20 '
                      onClick={openAddModal}
                      disabled={isEditDisable}
                    >
                      ADD
                    </button>
                  </div>
                </div>
              </div>

            <div className='table-responsive'>
              {data?.length >= 0 ?
                <Grid
                filter={dataState.filter}
                filterable={true}
                filterOperators={filterOperators}
                expandField="expanded"
                sort={dataState.sort}
                sortable={true}
                pageable={{
                 pageSizes: [25, 50, 100],
                 info: true,
                 previousNext: true,
                 buttonCount: 10,
                }}
               resizable={true}
               skip={dataState.skip}
               take={dataState.take}
               data={dataResult}
               onDataStateChange={dataStateChange}
               onRowClick={(e) => rowClickHandler(e.dataItem)}
               total={totalResult}
                >
                   <GridColumn
                    field='id'
                    title='ID'
                    filterable={true}
                    width="150px"
                    filter="numeric"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.id
                            ? e.dataItem.id
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='match_method'
                    title='Match Method'
                    filterable={true}
                    width="150px"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.match_method
                            ? e.dataItem.match_method
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='origin_location_fullname'
                    title='Origin'
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.origin_location_fullname
                            ? e.dataItem.origin_location_fullname
                            : ""}
                        </td>
                      );
                    }}
                    width="200px"
                  />
                  <GridColumn
                    field='destination_location_fullname'
                    title='Destination'
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.destination_location_fullname
                            ? e.dataItem.destination_location_fullname
                            : ""}
                        </td>
                      );
                    }}
                    width="200px"
                  />
                  <GridColumn
                    field='driver_pay_code'
                    title='Pay Code'
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.driver_pay_code
                            ? e.dataItem.driver_pay_code
                            : ""}
                        </td>
                      );
                    }}
                    width="200px"
                  />
                  <GridColumn
                    field='amount'
                    title='Amount($)'
                    width="150px"
                    filterable={true}
                    filter='numeric'
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.amount
                            ? Number(e.dataItem.amount)
                            : ""}
                        </td>
                      );
                    }}
                  />
                  <GridColumn
                    field='effective_date'
                    title='Effective Date'
                    filter='date'
                   format="{MM/dd/yyyy}"
                    width="250px"
                    cell={(e) => {
                    return (
                      <td>
                        {e.dataItem.effective_date ? convertdate(e.dataItem.effective_date) : "No Data"}
                      </td>
                    );
                     }}
                  />
                  <GridColumn
                    field='expiry_date'
                    title='Expiration Date'
                    filter='date'
                   format="{MM/dd/yyyy}"
                    width="250px"
                    cell={(e) => {
                    return (
                      <td>
                        {e.dataItem.expiry_date ? convertdate(e.dataItem.expiry_date) : "No Data"}
                      </td>
                    );
                     }}
                  />              
                   <GridColumn
                    field='included_drivers'
                    title='Included Drivers'
                    filterable={true}
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.included_drivers
                            ? e.dataItem.included_drivers
                            : ""}
                        </td>
                      );
                    }}
                    width="150px"
                  />
                  <GridColumn
                         field='Action'
                         title='Action'
                         width='150px'
                         cell={(e) => {
                           return(
                             <td>
                               <button
                                 type="button"
                                 className="btn-blue_schedule "
                                 onClick={(i) => auditLogModalOpenHandler(e.dataItem)}
                               >
                                 Audit Log
                               </button>
                             </td>
                           );
                         }}
                     />

                </Grid> :
                <div>
                  <ProgressBar animated now={100} />
                  <div className='middle loader--text1'> </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
    <AuditLogModalComponent ref={auditLogComponentRef} />

     {/* Edit Driver Pay Lane Override*/}
    <Modal
        show={isEditModalOpen}
        onHide={handleEditClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Edit Driver Pay Lane Overrides
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mt_10'>
            <div className='mt_10'>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Match Method*</label>
                <div className='mt_10'>
                <Autocomplete
                  id="combo-box-demo"
                  options={masterData?.matchMethod}
                  getOptionLabel={(option) => option}
                  value={allField?.matchMethod}
                  onChange={(e,option)=>handleChange(option,"matchMethod")}
                  disabled={isEditDisable}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Match Method"
                      variant="outlined"
                      disabled={isEditDisable}
                    />
                  )}
                />
                </div>
              </div>
            </div>
            <div className='mt_10'>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Origin*</label>
                <div className='mt_10'>
                <Autocomplete
                  id="combo-box-demo"
                  options={masterData?.orgDestcode}
                  getOptionLabel={(option) => option}
                  value={allField?.origin}
                  onChange={(e,option)=>handleChange(option,"origin")}
                  disabled={isEditDisable}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Origin"
                      variant="outlined"
                      disabled={isEditDisable}
                    />
                  )}
                />
                </div>
              </div>
            </div>
            <div className='mt_10'>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Destination*</label>
                <div className='mt_10'>
                <Autocomplete
                  id="combo-box-demo"
                  options={masterData?.orgDestcode}
                  getOptionLabel={(option) => option}
                  value={allField?.destination}
                  onChange={(e,option)=>handleChange(option,"destination")}
                  disabled={isEditDisable}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Destination"
                      variant="outlined"
                      disabled={isEditDisable}
                    />
                  )}
                />
                </div>
              </div>
            </div>
            <div className="meterial_autocomplete">
              <label htmlFor="typeHeadAddStatus">Pay Code*</label>
              <Autocomplete
                  id="combo-box-demo"
                  options={masterData?.payCode}
                  getOptionLabel={(option) => option}
                  value={allField?.payCode}
                  onChange={(e,option)=>handleChange(option,"payCode")}
                  disabled={isEditDisable}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Pay Code"
                      variant="outlined"
                      disabled={isEditDisable}
                    />
                  )}
                />
            </div>
            <div className='mt_10'>
              <label htmlFor='tankwashcode'>Amount ($)*</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.payRate}
                  type='number'
                  name='minutes'
                  placeholder='Enter Pay Rate'
                  size='small'
                  onChange={(e) => { handleChange(e.target.value,"payRate") }}
                  disabled={isEditDisable}
                />
              </div>
            </div>
            <div className='mt_10'>
              <label htmlFor='datetime'>Effective Date*</label>
              <div className='meterial_autocomplete'>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: false,
                        fullWidth: true
                      }
                    }}
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    value={allField?.effectiveDate}
                    onChange={(date) => handleChange(date, "effectiveDate")}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={isEditDisable}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className='mt_10'>
              <label htmlFor='datetime'>Expiration Date</label>
              <div className='meterial_autocomplete'>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: false,
                        fullWidth: true
                      }
                    }}
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    value={allField?.expirationDate}
                    onChange={(date) => handleChange(date, "expirationDate")}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={isEditDisable}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className='mt_10'>
              <div className='meterial_autocomplete'>
              <label htmlFor='tankwashcode'>Included Drivers</label>
                 <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    multiple 
                    limitTags={1} 
                    options={masterData?.drivers}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"includedDriver")}
                    value={[...allField?.includedDriver]}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Included Driver's"
                        variant="outlined"
                      />
                    )}
                  />
                  </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleEditClose}>Close</Button>
          <Button
            type='submit'
             onClick={editDriverPayConfigHandler}
            disabled={isEditDisable}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

       {/* Add Driver Pay Lane Override */}
       <Modal
        show={isAddModalOpen}
        onHide={handleAddClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Add Driver Pay Lane Overrides
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mt_10'>
            <div className='mt_10'>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Match Method*</label>
                <div className='mt_10'>
                <Autocomplete
                  id="combo-box-demo"
                  options={masterData?.matchMethod}
                  getOptionLabel={(option) => option}
                  value={allField?.matchMethod}
                  onChange={(e,option)=>handleChange(option,"matchMethod")}
                  disabled={isEditDisable}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Match Method"
                      variant="outlined"
                      disabled={isEditDisable}
                    />
                  )}
                />
                </div>
              </div>
            </div>
            <div className='mt_10'>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Origin*</label>
                <div className='mt_10'>
                <Autocomplete
                  id="combo-box-demo"
                  options={masterData?.orgDestcode}
                  getOptionLabel={(option) => option}
                  value={allField?.origin}
                  onChange={(e,option)=>handleChange(option,"origin")}
                  disabled={isEditDisable}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Origin"
                      variant="outlined"
                      disabled={isEditDisable}
                    />
                  )}
                />
                </div>
              </div>
            </div>
            <div className='mt_10'>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Destination*</label>
                <div className='mt_10'>
                <Autocomplete
                  id="combo-box-demo"
                  options={masterData?.orgDestcode}
                  getOptionLabel={(option) => option}
                  value={allField?.destination}
                  onChange={(e,option)=>handleChange(option,"destination")}
                  disabled={isEditDisable}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Destination"
                      variant="outlined"
                      disabled={isEditDisable}
                    />
                  )}
                />
                </div>
              </div>
            </div>
            <div className="meterial_autocomplete">
              <label htmlFor="typeHeadAddStatus">Pay Code*</label>
              <Autocomplete
                  id="combo-box-demo"
                  options={masterData?.payCode}
                  getOptionLabel={(option) => option}
                  value={allField?.payCode}
                  onChange={(e,option)=>handleChange(option,"payCode")}
                  disabled={isEditDisable}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Enter Pay Code"
                      variant="outlined"
                      disabled={isEditDisable}
                    />
                  )}
                />
            </div>
            <div className='mt_10'>
              <label htmlFor='tankwashcode'>Amount ($)*</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={allField?.payRate}
                  type='number'
                  name='minutes'
                  placeholder='Enter Pay Rate'
                  size='small'
                  onChange={(e) => { handleChange(e.target.value,"payRate") }}
                  disabled={isEditDisable}
                />
              </div>
            </div>
            <div className='mt_10'>
              <label htmlFor='datetime'>Effective Date*</label>
              <div className='meterial_autocomplete'>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: false,
                        fullWidth: true
                      }
                    }}
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    value={allField?.effectiveDate}
                    onChange={(date) => handleChange(date, "effectiveDate")}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={isEditDisable}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className='mt_10'>
              <label htmlFor='datetime'>Expiration Date</label>
              <div className='meterial_autocomplete'>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: false,
                        fullWidth: true
                      }
                    }}
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    value={allField?.expirationDate}
                    onChange={(date) => handleChange(date, "expirationDate")}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={isEditDisable}
                  />
                </LocalizationProvider>
              </div>
            </div>
            <div className='mt_10'>
              <div className='meterial_autocomplete'>
              <label htmlFor='tankwashcode'>Included Drivers</label>
                 <div className='mt_10'>
                <Autocomplete
                    id="combo-box-demo"
                    multiple 
                    limitTags={1} 
                    options={masterData?.drivers}
                    getOptionLabel={(option) => option}
                    onChange={(e,option)=>handleChange(option,"includedDriver")}
                    value={[...allField?.includedDriver]}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Enter Included Driver's"
                        variant="outlined"
                      />
                    )}
                  />
                  </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleAddClose}>Close</Button>
          <Button
            type='submit'
             onClick={AdddriverpayconfigHandler}
            disabled={isEditDisable}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </> 
  )
}

export default DriverPayOverrideTable