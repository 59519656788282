import React, { useEffect, useState, useContext } from "react";
import BaselineShiping from "../../assets/images/baseline_local_shipping.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { withStyles } from '@mui/styles';

import Menu from '@mui/material/Menu';
import Config from '../../Config.json';
import ListItemText from '@mui/material/ListItemText';


import CarrierviewTooltip, {
  OrderPBToolTip,
  Order2PBToolTip,
  Order3PBToolTip,
  OrderTooltip,
  TrailerPBToolTip
} from "./carrierviewtooltip";

import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { DropContext } from "../../pages/planner/planner";
import { TextField, Button as MUIButton, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, FormControl, Select } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';
import Skeleton from '@mui/material/Skeleton';

import { ContextData } from "../appsession";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />

));
const PlannerBoardCarrierViewsection = (props) => {
  const context = React.useContext(DropContext);
  const [userData, setuserData] = useContext(ContextData);
  const {

    hourArr,
    carrierviewData,
    setCarrierPageNumber,
    carrierPageNumber
  } = props;


  const [selectedDate, setSelectedDate] = useState(() => {
    return new Date();
  });
  const [dragset, setdragset] = useState(Array.from(Array(48).keys()));

  
  const [carrierview, setcarrierview] = useState([])
  const [carrierPlannedMode, setCarrierPlannedModet] = useState(true);

  const dayToMillis = 86400000;
  const handleCarrierViewPagination = async (event, value) => {
    setCarrierPageNumber(value);
  };

  useEffect(() => {
    setcarrierview(carrierviewData)
  }, [carrierPageNumber, carrierviewData])

  return (
    <>
      <ToastContainer position='top-right' autoClose={2000} theme='colored' />
        <div className='main_planner_wrapper'>
          <div class='planner_main_container_outer'>
            <div className='planner_am_section'>HRS</div>
            <div className='planner_main_container'>
              {hourArr.map((item, key) => (
                <div key={key} className='planner_main_inner_container'>
                  <div className='hrs_wrapper'>
                    <p className='hrs_wrapper_time_text'>
                      {item == "0"
                        ? `12  A.M`
                        : item == "12"
                          ? `12  P.M`
                          : item > 11
                            ? `${item - 12}  P.M`
                            : `${item}  A.M`}
                    </p>
                    <div className='planner_day_time_inner'>
                      <div className='vl_plannertankwash'></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div class='planner_pm_section'>HRS</div>
          </div>
        </div>
        <div className='scroll_plannerboard_inner'>
          {carrierPlannedMode ? (
            carrierview.length > 0 ? carrierview.filter(it => it.pickup.planned_arrive_utc < context.selectedTimezoneDate + dayToMillis || it.delivery.planned_depart_utc >= context.selectedTimezoneDate).map((it, idx) => (
              <div key={idx} class='planner_main_container_outer'>
                <div className='planner_main_container_outer_image_container_right_text_wrapper'>
                  <div class='planner_main_container_outer_image_container-1'
                  >

                    <div>
                      <img
                        src={BaselineShiping}
                        alt='Baseline-Shiping'
                        title='Baseline-Shiping'
                        className='planner_left_image'
                      />
                    </div>


                    <p className='planner_main_container_outer_image_container_right_text-1'>
                      {/* {item.trailer_id} */}
                      Bulkmatic solutions
                    </p>
                  </div>
                  <div className='planner_main_container_outer_image_container_right_text-1'>
                      <p>O</p>
                      <p>T</p>
                      <p>S</p>
                    </div>
                </div>

                <div class='planner_dragable_container'>

                  <div className='planner_tick_container'>

                    {dragset.map((it2, index) => (
                      <div key={index} className='planner_dragable_inner'>

                      </div>
                    ))}
                  </div>
                  <div class='planner_dragable_inner_main_block'>
                    {/* top block */}
                    <div class='planner_draggable_main_block_row'>
                      <div

                        style={{

                          position: "absolute",
                          left:
                            it.pickup.planned_arrive_utc >=
                              context.selectedTimezoneDate
                              ? ((it.pickup.planned_arrive_utc -
                                context.selectedTimezoneDate) /
                                dayToMillis) *
                              100 +
                              "%"
                              : "0%",
                          width: `${(((it?.delivery.planned_depart_utc <
                            context.selectedTimezoneDate + dayToMillis
                            ? it?.delivery.planned_depart_utc
                            : context.selectedTimezoneDate +
                            dayToMillis) -
                            (it.pickup.planned_arrive_utc >=
                              context.selectedTimezoneDate
                              ? it.pickup.planned_arrive_utc
                              : context.selectedTimezoneDate)) /
                            dayToMillis) *
                            100
                            }%`,
                          display:
                            (((it?.delivery.planned_depart_utc <
                              context.selectedTimezoneDate + dayToMillis
                              ? it?.delivery.planned_depart_utc
                              : context.selectedTimezoneDate +
                              dayToMillis) -
                              (it.pickup.planned_arrive_utc >=
                                context.selectedTimezoneDate
                                ? it.pickup.planned_arrive_utc
                                : context.selectedTimezoneDate)) /
                              dayToMillis) *
                              100 >
                              0
                              ? "block"
                              : "none",
                        }}
                        className='planner_draggable_main_block_row_block planner_draggable_main_block_top_row_block'
                      >                             
                        <CarrierviewTooltip
                            tooltip={<OrderTooltip it={it} />}
                          >
                            <div className='tooltip_row_block '>
                            {it.order_id}
                            </div>
                          </CarrierviewTooltip>

                      </div>
                    </div>
                    {/* end */}
                    {/* middleblock */}
                    <div class='planner_draggable_main_block_row planner_draggable_main_block_middle_row'>
                      <div

                        style={{

                          position: "absolute",
                          left:
                            it.pickup.planned_arrive_utc >=
                              context.selectedTimezoneDate
                              ? ((it.pickup.planned_arrive_utc -
                                context.selectedTimezoneDate) /
                                dayToMillis) *
                              100 +
                              "%"
                              : "0%",
                          width: `${(((it?.delivery.planned_depart_utc <
                            context.selectedTimezoneDate + dayToMillis
                            ? it?.delivery.planned_depart_utc
                            : context.selectedTimezoneDate +
                            dayToMillis) -
                            (it.pickup.planned_arrive_utc >=
                              context.selectedTimezoneDate
                              ? it.pickup.planned_arrive_utc
                              : context.selectedTimezoneDate)) /
                            dayToMillis) *
                            100
                            }%`,
                          display:
                            (((it?.delivery.planned_depart_utc <
                              context.selectedTimezoneDate + dayToMillis
                              ? it?.delivery.planned_depart_utc
                              : context.selectedTimezoneDate +
                              dayToMillis) -
                              (it.pickup.planned_arrive_utc >=
                                context.selectedTimezoneDate
                                ? it.pickup.planned_arrive_utc
                                : context.selectedTimezoneDate)) /
                              dayToMillis) *
                              100 >
                              0
                              ? "block"
                              : "none",
                        }}
                        className='planner_draggable_main_block_row_block planner_draggable_main_block_middle_row_block'
                      >

                        
                        <CarrierviewTooltip
                            tooltip={<TrailerPBToolTip it={it} />}
                          >
                            <div className='tooltip_row_block '>
                            {it.trailer_id ? it.trailer_id : ""}
                            </div>
                          </CarrierviewTooltip>
                      </div>
                    </div>
                    {/* end */}
                    <div className="planner_draggable_main_block_row">

                      <div style={{

                        position: "absolute",
                        left:
                          it.pickup.planned_arrive_utc >=
                            context.selectedTimezoneDate
                            ? ((it.pickup.planned_arrive_utc -
                              context.selectedTimezoneDate) /
                              dayToMillis) *
                            100 +
                            "%"
                            : "0%",
                        width: `${(((it.pickup.planned_depart_utc <
                          context.selectedTimezoneDate + dayToMillis
                          ? it.pickup.planned_depart_utc
                          : context.selectedTimezoneDate +
                          dayToMillis) -
                          (it.pickup.planned_arrive_utc >=
                            context.selectedTimezoneDate
                            ? it.pickup.planned_arrive_utc
                            : context.selectedTimezoneDate)) /
                          dayToMillis) *
                          100
                          }%`,
                        display:
                          (((it.pickup.planned_depart_utc <
                            context.selectedTimezoneDate + dayToMillis
                            ? it.pickup.planned_depart_utc
                            : context.selectedTimezoneDate +
                            dayToMillis) -
                            (it.pickup.planned_arrive_utc >=
                              context.selectedTimezoneDate
                              ? it.pickup.planned_arrive_utc
                              : context.selectedTimezoneDate)) /
                            dayToMillis) *
                            100 >
                            0
                            ? "block"
                            : "none",
                      }}
                        className="planner_draggable_main_block_row_block planner_draggable_main_block_bottom_row_block1">

                        {it.pickup.stop_type === "PU" ? (
                          <CarrierviewTooltip
                            tooltip={<OrderPBToolTip it={it} />}
                          >


                            <div className='tooltip_row_block '>
                              {it.pickup.stop_type}
                            </div>
                          </CarrierviewTooltip>
                        ) : (
                          ""
                        )}

                      </div>
                      <div style={{

                        position: "absolute",
                        left:
                          it.pickup.planned_depart_utc >=
                            context.selectedTimezoneDate
                            ? ((it.pickup.planned_depart_utc -
                              context.selectedTimezoneDate) /
                              dayToMillis) *
                            100 +
                            "%"
                            : "0%",
                        width: `${(((it.delivery.planned_arrive_utc <
                          context.selectedTimezoneDate + dayToMillis
                          ? it.delivery.planned_arrive_utc
                          : context.selectedTimezoneDate +
                          dayToMillis) -
                          (it.pickup.planned_depart_utc >=
                            context.selectedTimezoneDate
                            ? it.pickup.planned_depart_utc
                            : context.selectedTimezoneDate)) /
                          dayToMillis) *
                          100
                          }%`,
                        display:
                          (((it.delivery.planned_arrive_utc <
                            context.selectedTimezoneDate + dayToMillis
                            ? it.delivery.planned_arrive_utc
                            : context.selectedTimezoneDate +
                            dayToMillis) -
                            (it.pickup.planned_depart_utc >=
                              context.selectedTimezoneDate
                              ? it.pickup.planned_depart_utc
                              : context.selectedTimezoneDate)) /
                            dayToMillis) *
                            100 >
                            0
                            ? "block"
                            : "none",
                      }} className="planner_draggable_main_block_row_block planner_draggable_main_block_bottom_row_block">
                        {it.movement_type === "L" ? (
                          <CarrierviewTooltip
                            tooltip={<Order3PBToolTip it={it} />}
                          >


                            <div className='tooltip_row_block '>
                              {it.movement_type}
                            </div>
                          </CarrierviewTooltip>
                        ) : (
                          ""
                        )}
                        {/* {it.movement_type} */}
                      </div>
                      <div style={{

                        position: "absolute",
                        left:
                          it.delivery.planned_arrive_utc >=
                            context.selectedTimezoneDate
                            ? ((it.delivery.planned_arrive_utc -
                              context.selectedTimezoneDate) /
                              dayToMillis) *
                            100 +
                            "%"
                            : "0%",
                        width: `${(((it.delivery.planned_depart_utc <
                          context.selectedTimezoneDate + dayToMillis
                          ? it.delivery.planned_depart_utc
                          : context.selectedTimezoneDate +
                          dayToMillis) -
                          (it.delivery.planned_arrive_utc >=
                            context.selectedTimezoneDate
                            ? it.delivery.planned_arrive_utc
                            : context.selectedTimezoneDate)) /
                          dayToMillis) *
                          100
                          }%`,
                        display:
                          (((it.delivery.planned_depart_utc <
                            context.selectedTimezoneDate + dayToMillis
                            ? it.delivery.planned_depart_utc
                            : context.selectedTimezoneDate +
                            dayToMillis) -
                            (it.delivery.planned_arrive_utc >=
                              context.selectedTimezoneDate
                              ? it.delivery.planned_arrive_utc
                              : context.selectedTimezoneDate)) /
                            dayToMillis) *
                            100 >
                            0
                            ? "block"
                            : "none",
                      }}
                        className="planner_draggable_main_block_row_block planner_draggable_main_block_bottom_row_block1">

                        {it.delivery.stop_type === "SD" || "DEL" ? (
                          <CarrierviewTooltip
                            tooltip={<Order2PBToolTip it={it} />}
                          >


                            <div className='tooltip_row_block '>
                              {it.delivery.stop_type}
                            </div>
                          </CarrierviewTooltip>
                        ) : (
                          ""
                        )}

                      </div>
                    </div>
                  </div>


                </div>
                <div class='planner_pm_section'></div>
              </div>
            )) : <div className="plannerText">No Planning to display</div>
          ) : (
            carrierview.length > 0 ? carrierview.filter(it => it.pickup.actual_arrival_utc < context.selectedTimezoneDate + dayToMillis || it.delivery.actual_departure_utc >= context.selectedTimezoneDate).map((it, idx) => (
              <div key={idx} class='planner_main_container_outer'>
                <div className='planner_main_container_outer_image_container_right_text_wrapper'>
                  <div class='planner_main_container_outer_image_container-1'
                  >

                    <div>
                      <img
                        src={BaselineShiping}
                        alt='Baseline-Shiping'
                        title='Baseline-Shiping'
                        className='planner_left_image'
                      />
                    </div>


                    <p className='planner_main_container_outer_image_container_right_text-1'>
                      {/* {item.trailer_id} */}
                      Bulkmatic solutions
                    </p>
                  </div>
                  <div className='planner_main_container_outer_image_container_right_text-1'>
                      <p>O</p>
                      <p>T</p>
                      <p>S</p>
                    </div>
                </div>

                <div class='planner_dragable_container'>

                  <div className='planner_tick_container'>

                    {dragset.map((it2, index) => (
                      <div key={index} className='planner_dragable_inner'>

                      </div>
                    ))}
                  </div>
                  <div class='planner_dragable_inner_main_block'>
                    {/* top block */}
                    <div class='planner_draggable_main_block_row'>
                      <div

                        style={{

                          position: "absolute",
                          left:
                            it.pickup.actual_arrival_utc >=
                              context.selectedTimezoneDate
                              ? ((it.pickup.actual_arrival_utc -
                                context.selectedTimezoneDate) /
                                dayToMillis) *
                              100 +
                              "%"
                              : "0%",
                          width: `${(((it?.delivery.actual_departure_utc <
                            context.selectedTimezoneDate + dayToMillis
                            ? it?.delivery.actual_departure_utc
                            : context.selectedTimezoneDate +
                            dayToMillis) -
                            (it.pickup.actual_arrival_utc >=
                              context.selectedTimezoneDate
                              ? it.pickup.actual_arrival_utc
                              : context.selectedTimezoneDate)) /
                            dayToMillis) *
                            100
                            }%`,
                          display:
                            (((it?.delivery.actual_departure_utc <
                              context.selectedTimezoneDate + dayToMillis
                              ? it?.delivery.actual_departure_utc
                              : context.selectedTimezoneDate +
                              dayToMillis) -
                              (it.pickup.actual_arrival_utc >=
                                context.selectedTimezoneDate
                                ? it.pickup.actual_arrival_utc
                                : context.selectedTimezoneDate)) /
                              dayToMillis) *
                              100 >
                              0
                              ? "block"
                              : "none",
                        }}
                        className='planner_draggable_main_block_row_block planner_draggable_main_block_top_row_block'
                      >                               
                          <CarrierviewTooltip
                            tooltip={<OrderTooltip it={it} />}
                          >
                            <div className='tooltip_row_block '>
                            {it.order_id}
                            </div>
                          </CarrierviewTooltip>
                      </div>
                    </div>
                    {/* end */}
                    {/* middleblock */}
                    <div class='planner_draggable_main_block_row planner_draggable_main_block_middle_row'>
                      <div

                        style={{

                          position: "absolute",
                          left:
                            it.pickup.actual_arrival_utc >=
                              context.selectedTimezoneDate
                              ? ((it.pickup.actual_arrival_utc -
                                context.selectedTimezoneDate) /
                                dayToMillis) *
                              100 +
                              "%"
                              : "0%",
                          width: `${(((it?.delivery.actual_departure_utc <
                            context.selectedTimezoneDate + dayToMillis
                            ? it?.delivery.actual_departure_utc
                            : context.selectedTimezoneDate +
                            dayToMillis) -
                            (it.pickup.actual_arrival_utc >=
                              context.selectedTimezoneDate
                              ? it.pickup.actual_arrival_utc
                              : context.selectedTimezoneDate)) /
                            dayToMillis) *
                            100
                            }%`,
                          display:
                            (((it?.delivery.actual_departure_utc <
                              context.selectedTimezoneDate + dayToMillis
                              ? it?.delivery.actual_departure_utc
                              : context.selectedTimezoneDate +
                              dayToMillis) -
                              (it.pickup.actual_arrival_utc >=
                                context.selectedTimezoneDate
                                ? it.pickup.actual_arrival_utc
                                : context.selectedTimezoneDate)) /
                              dayToMillis) *
                              100 >
                              0
                              ? "block"
                              : "none",
                        }}
                        className='planner_draggable_main_block_row_block planner_draggable_main_block_middle_row_block'
                      >

                        <div className='tooltip_row_block'>
                          {it.trailer_id ? it.trailer_id : ""}
                        </div>
                      </div>
                    </div>
                    {/* end */}
                    <div className="planner_draggable_main_block_row">

                      <div style={{

                        position: "absolute",
                        left:
                          it.pickup.actual_arrival_utc >=
                            context.selectedTimezoneDate
                            ? ((it.pickup.actual_arrival_utc -
                              context.selectedTimezoneDate) /
                              dayToMillis) *
                            100 +
                            "%"
                            : "0%",
                        width: `${(((it.pickup.actual_departure_utc <
                          context.selectedTimezoneDate + dayToMillis
                          ? it.pickup.actual_departure_utc
                          : context.selectedTimezoneDate +
                          dayToMillis) -
                          (it.pickup.actual_arrival_utc >=
                            context.selectedTimezoneDate
                            ? it.pickup.actual_arrival_utc
                            : context.selectedTimezoneDate)) /
                          dayToMillis) *
                          100
                          }%`,
                        display:
                          (((it.pickup.actual_departure_utc <
                            context.selectedTimezoneDate + dayToMillis
                            ? it.pickup.actual_departure_utc
                            : context.selectedTimezoneDate +
                            dayToMillis) -
                            (it.pickup.actual_arrival_utc >=
                              context.selectedTimezoneDate
                              ? it.pickup.actual_arrival_utc
                              : context.selectedTimezoneDate)) /
                            dayToMillis) *
                            100 >
                            0
                            ? "block"
                            : "none",
                      }}
                        className="planner_draggable_main_block_row_block planner_draggable_main_block_bottom_row_block1">

                        {it.pickup.stop_type === "PU" ? (
                          <CarrierviewTooltip
                            tooltip={<OrderPBToolTip it={it} />}
                          >


                            <div className='tooltip_row_block '>
                              {it.pickup.stop_type}
                            </div>
                          </CarrierviewTooltip>
                        ) : (
                          ""
                        )}

                      </div>
                      <div style={{

                        position: "absolute",
                        left:
                          it.pickup.actual_departure_utc >=
                            context.selectedTimezoneDate
                            ? ((it.pickup.actual_departure_utc -
                              context.selectedTimezoneDate) /
                              dayToMillis) *
                            100 +
                            "%"
                            : "0%",
                        width: `${(((it.delivery.actual_arrival_utc <
                          context.selectedTimezoneDate + dayToMillis
                          ? it.delivery.actual_arrival_utc
                          : context.selectedTimezoneDate +
                          dayToMillis) -
                          (it.pickup.actual_departure_utc >=
                            context.selectedTimezoneDate
                            ? it.pickup.actual_departure_utc
                            : context.selectedTimezoneDate)) /
                          dayToMillis) *
                          100
                          }%`,
                        display:
                          (((it.delivery.actual_arrival_utc <
                            context.selectedTimezoneDate + dayToMillis
                            ? it.delivery.actual_arrival_utc
                            : context.selectedTimezoneDate +
                            dayToMillis) -
                            (it.pickup.actual_departure_utc >=
                              context.selectedTimezoneDate
                              ? it.pickup.actual_departure_utc
                              : context.selectedTimezoneDate)) /
                            dayToMillis) *
                            100 >
                            0
                            ? "block"
                            : "none",
                      }} className="planner_draggable_main_block_row_block planner_draggable_main_block_bottom_row_block">
                        {it.movement_type === "L" ? (
                          <CarrierviewTooltip
                            tooltip={<Order3PBToolTip it={it} />}
                          >


                            <div className='tooltip_row_block '>
                              {it.movement_type}
                            </div>
                          </CarrierviewTooltip>
                        ) : (
                          ""
                        )}
                        {/* {it.movement_type} */}
                      </div>
                      <div style={{

                        position: "absolute",
                        left:
                          it.delivery.actual_arrival_utc >=
                            context.selectedTimezoneDate
                            ? ((it.delivery.actual_arrival_utc -
                              context.selectedTimezoneDate) /
                              dayToMillis) *
                            100 +
                            "%"
                            : "0%",
                        width: `${(((it.delivery.actual_departure_utc <
                          context.selectedTimezoneDate + dayToMillis
                          ? it.delivery.actual_departure_utc
                          : context.selectedTimezoneDate +
                          dayToMillis) -
                          (it.delivery.actual_arrival_utc >=
                            context.selectedTimezoneDate
                            ? it.delivery.actual_arrival_utc
                            : context.selectedTimezoneDate)) /
                          dayToMillis) *
                          100
                          }%`,
                        display:
                          (((it.delivery.actual_departure_utc <
                            context.selectedTimezoneDate + dayToMillis
                            ? it.delivery.actual_departure_utc
                            : context.selectedTimezoneDate +
                            dayToMillis) -
                            (it.delivery.actual_arrival_utc >=
                              context.selectedTimezoneDate
                              ? it.delivery.actual_arrival_utc
                              : context.selectedTimezoneDate)) /
                            dayToMillis) *
                            100 >
                            0
                            ? "block"
                            : "none",
                      }}
                        className="planner_draggable_main_block_row_block planner_draggable_main_block_bottom_row_block1">

                        {it.delivery.stop_type === "SD" || "DEL"? (
                          <CarrierviewTooltip
                            tooltip={<Order2PBToolTip it={it} />}
                          >


                            <div className='tooltip_row_block '>
                              {it.delivery.stop_type}
                            </div>
                          </CarrierviewTooltip>
                        ) : (
                          ""
                        )}

                      </div>
                    </div>
                  </div>


                </div>
                <div class='planner_pm_section'></div>
              </div>
            )) : <div className="plannerText">No Planning to display</div>
          )

          }

        </div>
               

          <div className="pagination_adjust">
            {Object.keys(carrierview).length >
              0 ? (
              <Stack spacing={2}>
                <Pagination
                  onChange={handleCarrierViewPagination}
                  count={Math.ceil(
                    carrierview?.length /
                    25
                  )}
                  page={carrierPageNumber}
                  color='primary'
                />
              </Stack>
            ) : (
              ""
            )}
          </div>





    </>
  );
};

export default PlannerBoardCarrierViewsection;
