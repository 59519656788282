
import { BaseConfig } from "../baseConfig";
import * as fflate from 'fflate';
import { data } from "jquery";
import { DateTime } from "luxon";
// import Field from "../models/fieldsModel";
import axios from 'axios';

export const addDriverPayConfig = async (payload) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/createDriverPayConfig"
        const driverPayConfigData = await axios.post(url, payload);
        return driverPayConfigData
    } catch (err) {
        return err;
    }
}

export const updateDriverPayConfig = async (id, payload) => {

    try {
        const url = BaseConfig.ApiEndPoint + "/updatedriverpayconfig/" + id;
        const driverPayConfigData = await axios.put(url, payload);
        return driverPayConfigData;
    } catch (err) {
        return err;
    }

}

export const deleteDriverPayConfig = async (id) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/deletedriverpayconfig/${id}`
        const driverPayConfigData = await axios.delete(url);

        return driverPayConfigData
    } catch (error) {
        return error
    }
}

export const allDriverpayRecords = async (payload) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/getallPayrecordBystatus`;
        const driverPayResponse = await axios.post(url, payload);

        if (driverPayResponse?.data && driverPayResponse.data.compressedbase64string) {
            const strData = atob(driverPayResponse.data.compressedbase64string);
            const charData = strData.split("").map((x) => x.charCodeAt(0));
            const binData = new Uint8Array(charData);

            let apiDataUnCompressed = fflate.unzlibSync(binData);
            let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
            const decompressedObject = JSON.parse(apiDataUnCompressedString);

            // Return the decompressed object
            console.log(decompressedObject)
            return decompressedObject;
        } else {
            throw new Error("No Data");
        }
    } catch (error) {
        return error;
    }
};

export const allMovementDetailsForEdit = async (driver_id) => {
    try {
        const payload = {
            "driver_id": driver_id
        }
        const url = `${BaseConfig.ApiEndPoint}//getLatestMovements`;
        const driverPayResponse = await axios.post(url, payload);
        return driverPayResponse;
    } catch (error) {
        return error;
    }
};

export const getWeekOptions = async () => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/gettransferredPay`
        const getWeeks = await axios.get(url);
        return getWeeks
    } catch (error) {
        return error
    }
}

export const updateDriverPayStatus = async (id, payload) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/updatedriverpaystatus/${id}`
        const response = await axios.put(url, payload);
        return response
    } catch (error) {
        return error
    }

}

export const driverPayApproveSearch = async (searchTerms,sortData, page, size, id, selectedTab) => {
    let decompressedObject;
    let payload_data = {
        terminal_id: id,
        searchdata: [],
        shortData: sortData
    };

    searchTerms.forEach((term) => {
        const { field, value, operator } = term;
        if (
            field === "transaction_date" ||
            field === "pay_date" ||
            field === "authorized_on" ||
            field === "approved_on" ||
            field === "transfer_on" ||
            field === "effective_date" ||
            field === "created_on" ||
            field === "updated_on"
        ) {
            const formattedDate = DateTime.fromJSDate(new Date(value)).toFormat("yyyy-MM-dd");
            payload_data.searchdata.push({
                [field]: formattedDate,
                operator: operator,
            });
        } else {
            payload_data.searchdata.push({
                [field]: value,
                operator: operator,
            });
        }
    });

    
    if(selectedTab == 'Transferred') {
        payload_data.searchdata.push({
            transfer_on: null,
            operator: "neq"
        })
    }else{
        payload_data.searchdata.push({
            transfer_on: null,
            operator: "eq"
        }, {
            status: selectedTab,
            operator: "eq"

        })

    }
    const url = `${BaseConfig.ApiEndPoint}/searchApprovedDriverPay?page=${page}&size=${size}`;

    try {
        const response = await axios.post(url, payload_data);

        if (response?.data && response.data.compressedbase64string) {
            const strData = atob(response.data.compressedbase64string);
            const charData = strData.split("").map((x) => {
                return x.charCodeAt(0);
            });
            const binData = new Uint8Array(charData);

            let apiDataUnCompressed = fflate.unzlibSync(binData);
            let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
            decompressedObject = JSON.parse(apiDataUnCompressedString);
        } else {
            throw new Error("Invalid response format: response.data is undefined or null");
        }
    } catch (error) {
        console.error("An error occurred while performing driver pay search:", error);
        throw error;
    }
    return decompressedObject;
};

export const driverPayImport = async function (data) {
    try {
        const url = `${BaseConfig.ApiEndPoint}/importFileDriverpay`
        const response = await axios.post(url, data);

        return response.data;
    } catch (error) {
        return error
    }
}

export const driverPayExport = async function (date) {
    try {
        const url = `${BaseConfig.ApiEndPoint}/exportFileDriverpay/${date}`;
        const response = await axios.get(url);
        return response.data.res.data;
    } catch (error) {
        return error;
    }
}

export const createDriverPay = async (payload) => {

    try {
        const url = BaseConfig.ApiEndPoint + "/createdriverpay"
        const driverPayConfigData = await axios.post(url, payload);
        return driverPayConfigData
    } catch (err) {
        return [];
    }

}

export const getMovmentById = async (payload) => {

    try {
        const url = BaseConfig.ApiEndPoint + "/getmovementstopbyid"
        const moveInfo = await axios.post(url, payload);
        return moveInfo.data.data;
    } catch (err) {
        throw err;

    }

}
export const getPayRate = async (payload) => {

    try {
        const url = BaseConfig.ApiEndPoint + "/getpayrate"
        const payRateData = await axios.post(url, payload);
        return payRateData.data;
    } catch (err) {
        throw err;

    }

}

export const transferredDriverPayStatus = async (payload) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/driverpayTransfer"
        const trasnferResponse = await axios.post(url, payload);
        return trasnferResponse;
    } catch (err) {
        throw err;

    }
}

export const getAllMovements = async (payload) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/getLatestMovements"
        const trasnferResponse = await axios.post(url, payload);
        return trasnferResponse;
    } catch (err) {
        throw err;
    }
}

export const DeleteDriverPayByException = async (tabname, id) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/grcfordelete/${tabname}/${id}`;
        const response = await api({
            method: 'delete',
            url: url
        })
        return response
    }
    catch (err) {
        throw err
    }
}

export const UpdateDriverPayByDistanceExceptionLaneOverride = async (tabname,payload, id) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/grcforupdate/${tabname}/${id}`;
        const response = await axios.put(url, payload);
        return response
    }
    catch (err) {
        throw err
    }
}

export const AddDriverPayByDistanceExceptionLaneOverride = async (tabname,payload) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/grcforadd/${tabname}`;
        const response = await axios.post(url, payload);
        return response
    }
    catch (err) {
        throw err
    }
}

export const GetAllTerminalWithName = async () => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/terminals`;
        const response = await axios.get(url);
        return response.data.data
    }
    catch (err) {
        throw err
    }
}

export const GetAllCommodityWithName = async () => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/getAllactivecommodities `;
        const response = await axios.get(url);
        return response.data.data
    }
    catch (err) {
        throw err
    }
}

export const getallordertype = async () => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/getallordertype`;
        const response = await axios.get(url);
        return response.data.data
    }
    catch (err) {
        throw err
    }
}

export const getAllEquipmenttype = async () => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/getAllEquipmenttype`;
        const response = await axios.get(url);
        return response.data.data
    }
    catch (err) {
        throw err
    }
}

export const searchDriverpayKendo = async (tableName,searchTerms,sortData,page,size) => {
    let decompressedObject;
    try {
        const url = `${BaseConfig.ApiEndPoint}/grcforsearch/${tableName}?page=${page}&size=${size}`
        let data={
            searchdata:searchTerms,
            shortData:sortData
        }
        const response = await axios.post(url,data);
        if (response?.data && response?.data?.compressedbase64string) {
            const strData = atob(response.data.compressedbase64string);
            const charData = strData.split("").map((x) => {
                return x.charCodeAt(0);
            });
            const binData = new Uint8Array(charData);

            let apiDataUnCompressed = fflate.unzlibSync(binData);
            let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
            decompressedObject = JSON.parse(apiDataUnCompressedString);
        } else {
            throw new Error("Invalid response format: response.data is undefined or null");
        }
        return decompressedObject?.data
    }
    catch (err) {
        throw err
    }
}
