import React, { useState, useEffect, useContext } from "react";
import { ContextData } from "../../components/appsession";
import "react-datepicker/dist/react-datepicker.css";
import { Picky } from "react-picky";
import "react-picky/dist/picky.css";
const YardcheackHeader = (props) => {
	const { currentTerminalCount, yardCheckLength, terminalsOptions } = props;
	const [userData, setuserData] = useContext(ContextData);
	const [regionList, setRegionList] = useState([]);
	const [selectedRegion, setSelectedRegion] = useState([]);
	const [terminalList, setTerminalList] = useState([]);
	const [terminalListData, setTerminalListData] = useState([]);
	const [selectedTerminal, setSelectedTerminal] = useState([]);
	const [userTerminals, setUserTerminals] = useState([]);

	useEffect(() => {
		let regions = [];
		let terminals = [];
		let uniqueRegion = [...new Set(terminalsOptions.map((r) => r.region))];
		uniqueRegion = Object.values(uniqueRegion);
		uniqueRegion.map((el, index) => {
			let regionObj = {
				label: el,
				value: index,
			};
			regions.push(regionObj);
		});
		for (let i = 0; i < terminalsOptions.length; i++) {
			let terminalObj = {
				label: `${terminalsOptions[i].terminal_id} - ${terminalsOptions[i].name}`,
				value: terminalsOptions[i].terminal_id,
			};
			terminals.push(terminalObj);
		}
		setRegionList(regions);
		setSelectedRegion(regions);
		setTerminalList(terminals);
		setTerminalListData(terminals);
	}, [terminalsOptions.length]);

	useEffect(() => {
		let terminal = [];
		let filterTerminal = userTerminals.filter((terminal) =>
			selectedRegion.map((o) => o.label).includes(terminal.region)
		);
		filterTerminal.map((el, index) => {
			let terminalObj = {
				label: `${el.code}-${el.city}`,
				value: index,
			};
			terminal.push(terminalObj);
		});
		setTerminalList(terminal);
		setSelectedTerminal(terminal);
		if (selectedRegion.length == 0) {
			setTerminalList(terminalListData);
		}
	}, [selectedRegion.length]);

	useEffect(() => {
		let terminals = [];
		let filterTerminal = terminalsOptions.filter((terminal) =>
			selectedRegion.map((o) => o.label).includes(terminal.region)
		);
		for (let i = 0; i < filterTerminal.length; i++) {
			let terminalObj = {
				label: `${filterTerminal[i].terminal_id} - ${filterTerminal[i].name}`,
				value: filterTerminal[i].terminal_id,
			};
			terminals.push(terminalObj);
		}
		setTerminalList(terminals);
		setSelectedTerminal(terminals);
		if (selectedRegion.length == 0) {
			setTerminalList(terminalListData);
			setSelectedTerminal([]);
		}
	}, [selectedRegion]);

	useEffect(() => {
		props.parentCallback(selectedTerminal.map((terminal) => terminal.value));
	}, [selectedTerminal]);

	const onChangeRegion = (option) => {
		setSelectedRegion(option);
	};

	const onChangeTerminal = (option) => {
		setSelectedTerminal(option);
	};

	return (
		<div className="row df mt_30">
			<div className="tabs">
				<input type="radio" name="tabs" id="tabone" checked={true} />
				<label htmlFor="tabone">Yard Checks</label>
				<div className="tab">
					<div className="card-body">
						 
						<div className="row df top_adjust">
							<div className="w-25">
								<Picky
									options={regionList}
									labelKey="label"
									valueKey="value"
									placeholder="Regions"
									multiple={true}
									includeFilter
									includeSelectAll
									value={selectedRegion}
									numberDisplayed={1}
									manySelectedPlaceholder="Regions : %s "
									allSelectedPlaceholder="Regions : All"
									onChange={setSelectedRegion}
									renderSelectAll={({
										filtered,
										tabIndex,
										allSelected,
										toggleSelectAll,
										multiple,
									}) => {
										// Don't show if single select or items have been filtered.
										if (multiple && !filtered) {
											return (
												<div className="select_multiple_container">
													<div
														tabIndex={tabIndex}
														role="option"
														className="option"
														onClick={toggleSelectAll}
														onKeyPress={toggleSelectAll}
													>
														<button className="selectall_btn">
															Select all
														</button>
													</div>
													<div
														tabIndex={tabIndex}
														role="option"
														className="option"
														onClick={() => {
															setSelectedRegion([]);
														}}
														onKeyPress={toggleSelectAll}
													>
														<button className="selectall_btn">Clear All</button>
													</div>
												</div>
											);
										}
									}}
								/>
							</div>
							<div className="multiselect ml_30 pr w-25 terminal_drop_fixed">
								<div className="dropdownadjust">
									<Picky
										options={terminalList}
										labelKey="label"
										valueKey="value"
										placeholder="Terminals"
										multiple={true}
										includeFilter
										includeSelectAll
										value={selectedTerminal}
										numberDisplayed={1}
										manySelectedPlaceholder="Terminals : %s "
										allSelectedPlaceholder="Terminals : All"
										onChange={setSelectedTerminal}
										renderSelectAll={({
											filtered,
											tabIndex,
											allSelected,
											toggleSelectAll,
											multiple,
										}) => {
											// Don't show if single select or items have been filtered.
											if (multiple && !filtered) {
												return (
													<div className="select_multiple_container">
														<div
															tabIndex={tabIndex}
															role="option"
															className="option"
															onClick={toggleSelectAll}
															onKeyPress={toggleSelectAll}
														>
															<button className="selectall_btn">
																Select all
															</button>
														</div>
														<div
															tabIndex={tabIndex}
															role="option"
															className="option"
															onClick={() => {
																setSelectedTerminal([]);
															}}
															onKeyPress={toggleSelectAll}
														>
															<button className="selectall_btn">
																Clear All
															</button>
														</div>
													</div>
												);
											}
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default YardcheackHeader;
