import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { tankWashDragContext } from "../../pages/tankWash/tankWash";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Tankwashtooltip, {
  TankwasdataTooltip
} from "./tankwashtooltip";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Removetankwashorder} from "../../services/tankWashService";
import Skeleton from '@mui/material/Skeleton';
import errorMessageDisplay from "../../utils/errorMessageDisplay";

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  },
  customTooltip: {
    maxWidth: 'none',
    backgroundColor: "#4267B2",
    borderColor: "#2C4F95",
    borderStyle: "solid",
    borderWidth: "2px",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.54)",
    padding: "12px",
    borderRadius: "10px"
  },
  customArrow: {
    color: "#4267B2",
    fontSize: "20px"
  },
}));

const TankwashDataBar = (props) => {
  const { barData, bay, index, selectedLocation, selectedDateInToMilliSeconds} = props;
  const context = React.useContext(tankWashDragContext);
  const [isLoading, setIsLoading] = React.useState(false); 


  const start =
    barData?.start ?
      ((barData.start -
        DateTime.fromMillis(barData.start).setZone(selectedLocation?.timezone).startOf("day").toMillis()) /
        (24 * 60 * 60 * 1000)) * 100 : '';

  const end =
    barData?.end ?
      ((barData.end -
        DateTime.fromMillis(barData.end).setZone(selectedLocation?.timezone).startOf("day").toMillis()) /
        (24 * 60 * 60 * 1000)) *
      100 : '';

  const barStyle = {
    width: `${end - start}%`,
    left: `${start}%`,
  };

  
  const getClassNameColor = (barData) => {

    let csstopclass = "mainBar_top_in_time";
    if (barData.out_date_utc) {
      if (
        barData.in_date_utc > barData.start_time_utc ||
        barData.out_date_utc > barData.end_time_utc
      ) {
        return (csstopclass = "mainBar_top");
      } else {
        return (csstopclass = "mainBar_top_in_time");
      }
    }
    if (barData.in_date_utc && barData.out_date_utc == null) {
      if (barData.in_date_utc > barData.start_time_utc) {
        return (csstopclass = "mainBar_top1");
      } else {
        return (csstopclass = "mainBar_top1_in_time");
      }
    }
    if (barData.is_scheduled && barData.in_date_utc == null) {
      return (csstopclass = "mainBar_top2");
    }
    // else{
    //   return (
    //     csstopclass = "mainBar_top"
    //   )
    // }
    return csstopclass;
  }



  const removeTankwashData = async (e, bar, selectedDateInToMilliSeconds, selectedLocation) => {
    try {
      setIsLoading(true); 
      const TankwashRemoveData = await Removetankwashorder({ id: bar.id, is_scheduled: false })
      context.setdatarefresh(!context.datarefresh)
      toast.success(
        "Trailer work order removed successfully",
        "Success"
      )
    }
    catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Trailer work order didnt remove successfully, Please Refresh Your Screen', { autoClose: false } );
      }
    }
    setIsLoading(false);
  }

  const [dragStyle, setDragStyle] = useState({ opacity: 1 });
  return (
    <> 

    <Tankwashtooltip
    tooltip={<TankwasdataTooltip it={barData} selectedLocation={selectedLocation}/>}
    >
    
      {barData ?

        
          <div
            draggable
            onDragStart={() => {
              context.setDragData({ bay: bay, index: index });
              context.setDragFrom("dataBar");
            }}
            onDrag={(e) => {
              e.dataTransfer.setDragImage(e.target, 0, 0);
              setDragStyle({ opacity: 0, pointerEvents: "none" });
            }}
            onDragEnd={() => {
              setDragStyle({ opacity: 1 });
            }}
            style={{ ...barStyle, ...dragStyle }}
            className={getClassNameColor(barData)}
          >
            {barData?.title}
            {!barData.in_date_utc?(
              <div className='close_planning_outer_tankwash'>
              <div
                className='close_planning_tankwash orderidfix'
                onClick={(e) =>
                  removeTankwashData(
                    e,
                    barData,selectedDateInToMilliSeconds,selectedLocation
                  )
                }
              ></div>
            </div>
            ):(
              <></>
            )}
            {isLoading ? ( // added skeleton loader
              <div className='loader_skeleton'>
                <Skeleton variant='rectangular' />
              </div>
            ) :
              <></>
            }
          </div>
        : <></>
        
        }
    </Tankwashtooltip>

    </>

  );
};

export default TankwashDataBar;
