import React, { useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { makeStyles } from "@mui/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  createtankwashprocedure,
  getAllWashcodes,
  getAlltankwashtypeprocedure,
  getTankwashTypeById,
} from "../../services/tankWashService";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Autocomplete } from "@mui/lab";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { Picky } from "react-picky";
import Washprocedure_table from "../tankwashcodetable/washprocedure_table";
import { ToastContainer, toast } from "react-toastify";
import { useContext } from "react";
import EditTankWashModel from "../tankwashcodetable/EditTankWashModel";
import { ContextData } from "../../components/appsession";
import errorMessageDisplay from "../../utils/errorMessageDisplay";

const Tankwashcodetable = (props) => {
  const { descriptionOptions, accessDisabled } = props;
  const [tankwashcode, settankwashcode] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [views, setviews] = useState(false);
  const [dataforaddTankwashcode, setdataforaddTankwashcode] = useState({});
  const [selecteddescriptionOptions, setSelecteddescriptionOptions] = useState(
    []
  );
  const [descriptionsOptions, setdescriptionsOptions] = useState([]);
  const [washprocedureData, setwashprocedureData] = useState([]);
  const [tankwashtypeprocedureData, settankwashtypeprocedureData] = useState(
    []
  );
  // const [modalviewShow, setModalViewShow] = useState(false);
  const [procedureSequenceForAdd, setprocedureSequenceForAdd] = useState([])
  const [procedureSequenceForEdit, setprocedureSequenceForEdit] = useState([])
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [editTankWash, setEditTankWash] = useState({});
  const [isactivedata, setisactivedata] = useState({});
  const [userData, setuserData] = useContext(ContextData);

  const useStyles = makeStyles((theme) => ({
    statuscolor: {
      textAlign: "center!important",
      fontWeight: "bold",
      fontSize: 15
    },
  }));
  const classes = useStyles();

  const [dataForType, setdataForType] = useState([
    {
      type: "I", value: "Interior"
    },
    {
      type: "E", value: "Exterior"
    },
  ]);

  const [dataForIsactive, setdataForIsactive] = useState([
    {
      is_active: true, value: "Yes"
    },
    {
      is_active: false, value: "No"
    },
  ]);
  useEffect(() => {
    let descriptiondropdown = [];

    for (let i = 0; i < descriptionOptions.length; i++) {
      let obj = {
        label: `${descriptionOptions[i].description} - ${descriptionOptions[i].category}`,
        value: descriptionOptions[i].wash_proc,

        category: descriptionOptions[i].category,
        description: descriptionOptions[i].description,
        wash_proc: descriptionOptions[i].wash_proc,
        active: descriptionOptions[i].active,
      };

      descriptiondropdown.push(obj);
    }
    setdescriptionsOptions(descriptiondropdown);
    //setSelecteddescriptionOptions(descriptiondropdown)
  }, [descriptionOptions.length]);

  useEffect(async () => {
    const procedurewash_data = await getAlltankwashtypeprocedure();
    settankwashtypeprocedureData(procedurewash_data);
  }, []);

  const viewsHandler = () => {
    // let selectedData = []
    // let filterselected = tankwashtypeprocedureData.filter((it) =>
    //   selecteddescriptionOptions.map((o) => o.value).includes(it.wash_proc)
    // );
    // selectedData.push(filterselected)
    // setwashprocedureData(selectedData)
    setviews(true);
    setModalViewShow(true);
    // setwashprocedureData([])
  };
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "type",
        dir: "asc",
      },
      {
        field: "wash_code",
        dir: "asc",
      },
      {
        field: "description",
        dir: "asc",
      },
    ],
  });

  const [dataResult, setDataResult] = useState(
    process(tankwashcode, dataState)
  );

  const dataStateChange = (event) => {
    setDataResult(process(tankwashcode, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(tankwashcode, dataState));
  }, [tankwashcode]);

  useEffect(async () => {
    setIsLoading(true);
    const washcodedata = await getAllWashcodes();
    settankwashcode(washcodedata);
    setIsLoading(false);
  }, []);

  /// add modal variable declare //
  const capturewashcode = (value) => {
    setdataforaddTankwashcode((item) => ({
      ...item,
      wash_code: value,
    }));
  };
  const descriptionChangeHandler = (e) => {
    setdataforaddTankwashcode((item) => ({
      ...item,
      description: e.target.value,
    }));
  };

  // const typeChangeHandler = (value) => {
  //   setdataforaddTankwashcode((item) => ({
  //     ...item,
  //     type: value,
  //   }));
  // };

  const isactiveChangeHandler = (e, value)=>{
    if (value != null) {
      setdataforaddTankwashcode((prevState) => ({
        ...prevState,
        is_active: value.is_active,
      }));
    } else {
      setdataforaddTankwashcode((prevState) => ({
        ...prevState,
        is_active: null,
      }));
    }
    
  }

  const typeChangeHandler = (e, value) => {
    if (value != null) {
      setdataforaddTankwashcode((prevState) => ({
        ...prevState,
        type: value.type,
      }));
    } else {
      setdataforaddTankwashcode((prevState) => ({
        ...prevState,
        type: null,
      }));
    }

  };

  const captureDuration = (value) => {
    setdataforaddTankwashcode((item) => ({
      ...item,
      minutes: value,
    }));
  };
  const procedureSequence = (data) => {
    setprocedureSequenceForAdd(data)
    setprocedureSequenceForEdit(data)
  }

  const handleAmountChange = (value) => {
    setdataforaddTankwashcode((prevState) => ({
      ...prevState,
      amount: value !== null ? parseFloat(value) : null
    }));
  };

  const saveTankprocedureHandler = async () => {
    try {

      dataforaddTankwashcode.wash_type_procedure = procedureSequenceForAdd

      const Addtankprocedure = await createtankwashprocedure(dataforaddTankwashcode);
      if (Addtankprocedure.length > 0) {
        settankwashcode(Addtankprocedure);
        setModalShow(false);
      }
      return toast.success("Tank wash types added succesfully", "success");
    }catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Wash types has not been added succesfully, Please Refresh Your Screen', { autoClose: false } );
      }
    }
  };
  const openAddModal = () => {
    setModalShow(true)
    setwashprocedureData([])
    setSelecteddescriptionOptions([])

  }
  const selectChangeHandler = async (data) => {
    setSelecteddescriptionOptions(data);
    setwashprocedureData(data);
  };
  
  const openCloneModal = async (e, item) => {
    const washTypeById = await getTankwashTypeById(item.id)

    let washProc = [];

    for (let i = 0; i < washTypeById.data.data.wash_type_procedure.length; i++) {
      let obj = {
        label: `${washTypeById.data.data.wash_type_procedure[i]?.descr} - ${washTypeById.data.data.wash_type_procedure[i]?.category}`,
        value: washTypeById.data.data.wash_type_procedure[i]?.wash_proc,

        category: washTypeById.data.data.wash_type_procedure[i]?.category,
        description: washTypeById.data.data.wash_type_procedure[i]?.descr,
        wash_proc: washTypeById.data.data.wash_type_procedure[i]?.wash_proc,
        active: washTypeById.data.data.wash_type_procedure[i]?.is_active,
      };

      washProc.push(obj);
    }
    setSelecteddescriptionOptions(washProc);
    setwashprocedureData(washProc);
    setModalShow(true)
  }

  const editTankprocedureHandler = async (tankWash) => {
    const washTypeById = await getTankwashTypeById(tankWash.id)

    let washProc = [];

    for (let i = 0; i < washTypeById.data.data.wash_type_procedure.length; i++) {
      let obj = {
        label: `${washTypeById.data.data.wash_type_procedure[i]?.descr} - ${washTypeById.data.data.wash_type_procedure[i]?.category}`,
        value: washTypeById.data.data.wash_type_procedure[i]?.wash_proc,

        category: washTypeById.data.data.wash_type_procedure[i]?.category,
        description: washTypeById.data.data.wash_type_procedure[i]?.descr,
        wash_proc: washTypeById.data.data.wash_type_procedure[i]?.wash_proc,
        active: washTypeById.data.data.wash_type_procedure[i]?.is_active,
      };

      washProc.push(obj);
    }
    setSelecteddescriptionOptions(washProc);
    setwashprocedureData(washProc);
    setEditTankWash(tankWash)
    setModalShowEdit(true);
  }

  const EditClonetankwash = (props) => {
    return (
      <td className="adjustbutton">
        <button
          type="button"
          className="btn_blue_sm btn-blue ml_10"
          onClick={() => editTankprocedureHandler(props.dataItem)}
          disabled={accessDisabled ? true : false}
        >
          <i className="fa fa-pencil mr_5 del_icon" aria-hidden="true"></i>
          Edit
        </button>
        <button
          type="button"
          className="btn_blue_sm btn-blue ml_10"
          onClick={(i) => openCloneModal(i, props.dataItem)}
          disabled={accessDisabled ? true : false}
        >
          <i className="fa fa-pencil mr_5 del_icon" aria-hidden="true"></i>
          Clone
        </button>
      </td>
    )
  }
  const EditCloneTankWash = (props) => (
    <EditClonetankwash
      {...props}
      editTankprocedureHandler={editTankprocedureHandler}
      openCloneModal={openCloneModal}
    />
  )
  return (
    <>
      <ToastContainer position='top-right' theme='colored' />
      <div className='row special_row_flex'>
        <div className='col-xl-12'>
          <div className='card card_shadow'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-5 textadjust_terminal'>
                </div>
                <div className='col-md-7 textadjust_terminaladd_button '>
                  <div className='add_icon'>
                    <Tooltip title='Add'>
                      <button
                        type='button'
                        className='btn_blue btn-blue mr_10 mb-20 '
                        onClick={() => openAddModal()}
                        disabled={accessDisabled ? true : false}
                      >
                        ADD
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className='table-responsive'>
                {tankwashcode?.length > 0 ? (
                  <Grid
                    filter={dataState.filter}
                    filterable={true}
                    //filterOperators={filterOperators}
                    sort={dataState.sort}
                    sortable={true}
                    pageable={{
                      pageSizes: [5, 10, 20, 25, 50, 100],
                      info: true,
                      previousNext: true,
                      buttonCount: 10,
                    }}
                    resizable={true}
                    skip={dataState.skip}
                    take={dataState.take}
                    data={dataResult}
                    onDataStateChange={dataStateChange}
                  //onRowClick={(e) => props.parentcallback(true, e.dataItem)}
                  >
                    <GridColumn
                      field="is_active"
                      sortable={true}
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.is_active ? "True" : "False"}
                          </td>
                        );
                      }}
                      title="Is Active"
                      width="150px"
                      filterable={true}
                      filter={"boolean"}
                    />
                    <GridColumn
                      field='type'
                      title='Type'
                      filterable={true}
                      // cell={(e) => {
                      //   return (
                      //     <td>{e.dataItem.type ? e.dataItem.type : ""}</td>
                      //   );
                      // }}

                      cell={(e) => {
                        return (
                          <td

                          >
                            {e.dataItem.type === "I" ? "Interior" : "Exterior"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='wash_code'
                      title='Wash Code'
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.wash_code ? e.dataItem.wash_code : ""}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='description'
                      title='Description'
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.description
                              ? e.dataItem.description
                              : ""}
                          </td>
                        );
                      }}
                    />
                    
                    
                    <GridColumn
                      field='minutes'
                      title='Duration'
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.minutes ? e.dataItem.minutes : "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='amount'
                      title='Amount'
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.amount ?? "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      title="Action"
                      cell={EditCloneTankWash}
                    />
                  </Grid>

                ) : isLoading ? (
                  <div>
                    <ProgressBar animated now={100} />
                    <div className='middle loader--text1'> </div>
                  </div>
                ) : (
                  <div className='text-center'>No data found</div>
                )}
              </div>
            </div>
          </div>
          {/* modal for Add */}

          <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>
                Add Wash Type
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='mt_10'>
                <label for='tankwashcode'>Wash Code</label>
                <div className='meterial_autocomplete'>
                  <TextField
                    fullWidth
                    variant='outlined'
                    type='text'
                    name='minutes'
                    placeholder='Enter Wash Code...'
                    size='small'
                    onChange={(e) => capturewashcode(e.target.value)}
                  />
                </div>
              </div>
              <div className='mt_10'>
                <label for='exampleAddFormControlTextarea1'>Description</label>
                <textarea
                  className='form-control'
                  placeholder='Description....'
                  id='exampleAddFormControlTextarea1'
                  rows='3'
                  onChange={(e) => descriptionChangeHandler(e)}
                />
              </div>
              <div className='mt_10'>
                {/* <label for='tankwashcode'>Type</label>
                <div className='meterial_autocomplete'>
                  <TextField
                    fullWidth
                    variant='outlined'
                    type='text'
                    name='minutes'
                    placeholder='Enter Type...'
                    size='small'
                    onChange={(e) => typeChangeHandler(e.target.value)}
                  />
                </div> */}
                <div className="meterial_autocomplete">
                  <label htmlFor="typeHeadAddStatus">Is Active</label>

                  <Autocomplete
                    id="combo-box-demo"
                    options={dataForIsactive}
                    getOptionLabel={(option) => `${option.value}`}
                    onChange={isactiveChangeHandler}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Type..."
                        variant="outlined"
                      />
                    )}
                  />
                </div>
                <div className="meterial_autocomplete">
                  <label htmlFor="typeHeadAddStatus">Type</label>

                  <Autocomplete
                    id="combo-box-demo"
                    options={dataForType}
                    getOptionLabel={(option) => `${option.value}`}
                    onChange={typeChangeHandler}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Type..."
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
              <label for='tankwashcode'>Duration (mins)</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  type='number'
                  name='minutes'
                  placeholder='Enter Duration (mins)'
                  size='small'
                  onChange={(e) => captureDuration(e.target.value)}
                />
              </div>

              <div className='dropdownadjust'>
                <div className='mt_10'>
                  <label for='tankwashcode'>Wash Procedure </label>

                  <Picky
                    options={descriptionsOptions}
                    labelKey='label'
                    valueKey='value'
                    placeholder='Wash Procedure'
                    multiple={true}
                    includeFilter
                    includeSelectAll
                    value={selecteddescriptionOptions}
                    numberDisplayed={1}
                    manySelectedPlaceholder='Wash Procedure : %s '
                    allSelectedPlaceholder='Wash Procedure : All'
                    onChange={(e, data) => selectChangeHandler(e, data)}

                    // onChange={handletableopenclose}

                    renderSelectAll={({
                      filtered,
                      tabIndex,
                      allSelected,
                      toggleSelectAll,
                      multiple,
                    }) => {
                      // Don't show if single select or items have been filtered.
                      if (multiple && !filtered) {
                        return (
                          <div className='select_multiple_container'>
                            <div
                              tabIndex={tabIndex}
                              role='option'
                              className='option'
                              onClick={toggleSelectAll}
                              onKeyPress={toggleSelectAll}
                            // onChange={handletableopenclose}
                            >
                              <button className='selectall_btn'>
                                Select all
                              </button>
                            </div>
                            <div
                              tabIndex={tabIndex}
                              role='option'
                              className='option'
                              onClick={() => {
                                setSelecteddescriptionOptions([]);
                                setwashprocedureData([]);
                              }}
                              onKeyPress={toggleSelectAll}
                            >
                              <button className='selectall_btn'>
                                Clear All
                              </button>
                            </div>
                          </div>
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <div className='mt_10'>
                <label htmlFor='amount'>Amount</label>
                <div className='meterial_autocomplete'>
                  <TextField
                    fullWidth
                    variant='outlined'
                    type='number'
                    name='amount'
                    placeholder='Enter Amount...'
                    size='small'
                    onChange={(e) => handleAmountChange(e.target.value)} 
                  />
                </div>
              </div>
              <div className='mt_20'>
                {washprocedureData[0] ? (
                  <div>
                    <label for='tankwashcode'>
                      wash procedures : {washprocedureData[0]?.length}
                    </label>


                    <Washprocedure_table
                      washprocedureData={washprocedureData}
                      setwashprocedureData={setwashprocedureData}
                      procedureSequence={procedureSequence}

                    />
                  </div>
                ) : (
                  ""
                )}
                {/* <button type="button"
                    className="btn_blue btn-blue mr_10 mb-20 " onClick={viewsHandler}>
                    Wash Procedures</button>

                  {views && (
                    // <Washprocedure_table
                    // washprocedureData={washprocedureData}
                    // />

                    <Modal
                      show={modalviewShow}
                      onHide={() => setModalViewShow(false)}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                          Wash Procedures
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Washprocedure_table
                          washprocedureData={washprocedureData}
                          setwashprocedureData={setwashprocedureData}
                        />
                      </Modal.Body>
                      <Modal.Footer>

                      </Modal.Footer>
                    </Modal>
                  )} */}
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={(e) => setModalShow(false)}>Close</Button>
              <Button
                type='submit'
                onClick={(e) => saveTankprocedureHandler(e)}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>
          <EditTankWashModel
            descriptionsOptions={descriptionsOptions}
            selecteddescriptionOptions={selecteddescriptionOptions}
            setModalShowEdit={setModalShowEdit}
            modalShowEdit={modalShowEdit}
            editTankWash={editTankWash}
            setEditTankWash={setEditTankWash}
            washprocedureData={washprocedureData}
            setSelecteddescriptionOptions={setSelecteddescriptionOptions}
            setwashprocedureData={setwashprocedureData}
            settankwashcode={settankwashcode}
            procedureSequenceForEdit={procedureSequenceForEdit}
            procedureSequence={procedureSequence}
          />
        </div>
      </div>
    </>
  );
};

export default Tankwashcodetable;
