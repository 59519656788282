import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/header";
import AppBar from "../../components/appbar";
import CommoditygroupHeader from "../../components/commoditygroupHeader/commoditygroupHeader";
import CommoditygroupTable from "../../components/commoditygroupTable/commoditygroupTable";
import React, { useState, useEffect, useContext, createContext } from "react";
import CgByIdHeader from "../../components/commoditygroupByIdHeader/CgByIdHeader";
import CommoditygroupBodyForDetails from "../../components/commoditygroupBodyForDetails/commoditygroupBodyForDetails";
import CommoditygroupBodyForTrailers from "../../components/commoditygroupBodyForTrailers/commoditygroupBodyForTrailers";
import CommoditygroupBodyForTrailersInDetails from "../../components/CommoditygroupBodyForTrailersInDetails/CommoditygroupBodyForTrailersInDetails";
import { getAllCommodityGroups, getLoadUnloadInformationByCommodityGroup, getCommodityGroupByID } from "../../services/commoditygroupService";
import { DateTime } from "luxon";
import { ToastContainer, toast } from "react-toastify";
import errorMessageDisplay from "../../utils/errorMessageDisplay";

// Creating context
const CommodityGroupContext = createContext();
export { CommodityGroupContext };

function CommodityGroup() {
  const [allCommodityGroup, setallCommodityGroup] = useState([]);
  const [commodityGrouprClicked, setcommodityGrouprClicked] = useState(false);
  const [allTrailersDetails, setallTrailersDetails] = useState([]);
  const [headerTabName, setheaderTabName] = useState("details");
  const [commodityDropdown, setCommodityDropdown] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [commodityGroupById, setCommodityGroupById] = useState({}); // Selected Commodity Group's Details
  const [commodityGroupDataByIdForHeader, setCommodityGroupDataByIdForHeader] = useState([]); // Load & Unload data of selected Commodity Group
  const [commodityloadunloadData, setcommodityloadunloaddata] = useState([]);
  const [isDataLoaded, setisDataLoaded] = useState(false);
  const [commoditycode,setcommoditycode]=useState("");
   

  const handelcallbackFromHeader = (childdata) => {
    setcommodityGrouprClicked(childdata);
    setheaderTabName("details");
  };

  // This function is running after clicking on row
  const handleCommodityGroupByCode = async (childdata, commoditygroup) => {  
    setcommoditycode(commoditygroup?.code)
    try {
    
        const payload1 = {
          "commoditygroup_id": commoditygroup?.code,
        };

        const commodityGroupDataById = await getLoadUnloadInformationByCommodityGroup(payload1);
        setCommodityGroupDataByIdForHeader(commodityGroupDataById); //  Selected Commodity Group's Details
        setCommodityGroupById(commoditygroup); // Load & Unload data of selected Commodity Group


        if (commodityGroupDataById.length >= 0) {
          setcommodityGrouprClicked(childdata);
          setIsRefresh((prev) => !prev);
        }
            
    }catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false } );
      }
    }
  };

  const handelcallbackFromLocationHeader = (childdata) => {
    setheaderTabName(childdata);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const commodityGroup = await getAllCommodityGroups();
        setallCommodityGroup(commodityGroup);
        setCommodityDropdown(commodityGroup);
      } catch(err){
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false } );
        }
      }
    };  
    fetchData();
  }, []);  

  const parentCallBackFromTrailerGroup = (trailerdata) => {
    setallTrailersDetails(trailerdata);
  };

  const convertDateTime = (epoch_date) => {
    return (
      <td>
        {DateTime.fromMillis(parseInt(epoch_date * 1000))
          .toFormat("MM-dd-yyyy, hh:mm")
          .toString()}
      </td>
    );
  };

  return (
    <>
      <ToastContainer position='top-right' theme='colored' />
      <CommodityGroupContext.Provider
        value={{
          commodityloadunloadData,
          setcommodityloadunloaddata,
          isDataLoaded,
          setisDataLoaded,
          isRefresh,
          setIsRefresh,
          handleCommodityGroupByCode,
          commodityGroupById,
          commoditycode
        }}
      >
        <div id="wrapper">
          <Header
            userclicked={commodityGrouprClicked}
            parentcallback={handelcallbackFromHeader}
          ></Header>
          <AppBar></AppBar>
          <div className="content-page">
            <div className="content">
              <div className="container-fluid">
                {!commodityGrouprClicked ? (
                  <>
                    <CommoditygroupHeader
                      commodityDropdown={commodityDropdown}
                      allCommodityGroup={allCommodityGroup}
                    />
                    <CommoditygroupTable
                      allCommodityGroup={allCommodityGroup}
                      parentcallback={handleCommodityGroupByCode}
                    />
                  </>
                ) : (
                  <>
                    <CgByIdHeader
                      commodityGroupById={commodityGroupById}
                      commodityGroupDataByIdForHeader={commodityGroupDataByIdForHeader}
                      setCommodityGroupDataByIdForHeader={setCommodityGroupDataByIdForHeader}
                      parentcallback={handelcallbackFromLocationHeader}
                      allTrailersDetails={allTrailersDetails}
                      handleCommodityGroupByCode={handleCommodityGroupByCode}
                      isRefresh={isRefresh}
                    />
                    {headerTabName === "details" ? (
                      <div className="row special_row_flex">
                        <CommoditygroupBodyForDetails
                          commoditygroup={commodityGroupById}            
                          convertDateTime={convertDateTime}
                          handleCommodityGroupByCode={handleCommodityGroupByCode}                      
                        />
                        <CommoditygroupBodyForTrailersInDetails
                          commoditygroup={commodityGroupById}
                          parentCallBackFromTrailerGroup={
                            parentCallBackFromTrailerGroup
                          }
                          convertDateTime={convertDateTime}
                        />
                      </div>
                    ) : headerTabName === "Trailers" ? (
                      <CommoditygroupBodyForTrailers
                        commoditygroup={commodityGroupById}
                        parentCallBackFromTrailerGroup={
                          parentCallBackFromTrailerGroup
                        }
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </CommodityGroupContext.Provider>
    </>
  );
}

export default CommodityGroup;
