import axios from 'axios';

// Application Specific
import CommodityGroup from "../models/commoditygroupModel";
import Commodity from "../models/commodityModel";
import Trailer from "../models/trailerModel";
import { BaseConfig } from "../baseConfig";

  export const getAllCommodityGroups = async() => {
    const commodityGroup = new CommodityGroup();
    let commodityGroupObject = [];
    try {
      const url = BaseConfig.ApiEndPoint + "/cggroups";
      const commodityGroupApiData = await axios.get(url);
      commodityGroupObject = commodityGroupApiData.data.data.map((data) =>
        commodityGroup.parseApiCommodityGroupObject(data)
      );
    } catch (err) {
      return Promise.resolve(
        "There is a problem on retrieving trailer data. Please try again!"
      );
    }

    return Promise.resolve(commodityGroupObject);
  }

  export const resetLoadUnloadCommodityGroup = async(id) => {
    try {
      const url = BaseConfig.ApiEndPoint + "/ResetLoadUnload/" + id;
      const commodityGroupDataById = await axios.delete(url);
      console.log("deepu commodityGroupDataById", commodityGroupDataById);
      return commodityGroupDataById;
    } catch (err) {
      return err;
    }    
  };

  export const getLoadUnloadInformationByCommodityGroup = async(payload) => {
    try {
      const url = BaseConfig.ApiEndPoint + "/getLoadUnloadInformationByCommodityGroup";
      const commodityGroupDataById = await axios.post(url, payload);
      return commodityGroupDataById.data.data;
    } catch (err) {
      return err;
    }    
  };

  export const getCommodityGroupByID = async(payload, page, size) => {
    try {
      const url = `${BaseConfig.ApiEndPoint}/getAllCommodityLUDurationByCgId?page=${page}&size=${size}`;
      
      const commodityGroupDataById = await axios.post(url, payload);
      return commodityGroupDataById;
    } catch (err) {
      return err;
    }    
  };

  export const searchCommodity = async(payload,page,size) => {    
    try {
      const url = `${BaseConfig.ApiEndPoint}/searchCommodity?page=${page}&size=${size}`;
      
      const commodityGroupDataById = await axios.post(url, payload);
      return commodityGroupDataById;
    } catch (err) {
      return err;
    }    
  };

  export const modifyLoadunloadForCommodityGroup = async(payload) => {
    try {
      const url = BaseConfig.ApiEndPoint + "/modifyLoadunloadForCommodityGroup";
      const commodityGroupDataById = await axios.post(url, payload);     
      return commodityGroupDataById;
    } catch (err) {
      return err;
    }    
  };
  export const editCommodityLoadUnloadDuration = async(payload) => {
    try {
      const url = BaseConfig.ApiEndPoint + "/editcommodityloadunlaodduration";
      const commodityGroupDataById = await axios.post(url, payload);     
      return commodityGroupDataById;
    } catch (err) {
      return err;
    }    
  };

  export const getAllTrailerByCommodityGroupId = async(cgId, search) => {
    let allTrailers = [];
    const trailer = new Trailer();
    try {
      let data = {
        cgid: cgId,
        search: search,
      };
      const url = BaseConfig.ApiEndPoint + "/gettrailersbycgid";
      const trailercommodityApiData = await axios.post(url, data);
      

      allTrailers = trailercommodityApiData.data.data.map((data) =>
        trailer.parseApiTrailerObject(data)
      );
    } catch (err) {
      return Promise.reject(
        "There is a problem on retrieving trailer data. Please try again!"
      );
    }
    return Promise.resolve(allTrailers);
  }