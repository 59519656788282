import React, { useContext, useEffect, useState } from 'react'
import AppBar from '../../components/appbar';
import DriverPayConfigHeader from '../../components/driverPay/DriverPayConfigHeader';
import DriverPayConfigTable from '../../components/driverPay/DriverPayConfigTable';

import Header from '../../components/header';
import { ContextData } from '../../components/appsession';
import DriverPayEXceptionTable from '../../components/driverPay/DriverPayExceptionTable';
import DriverPayOverrideTable from '../../components/driverPay/DriverPayOverrideTable';
import DriverPayDistanceTable from '../../components/driverPay/DriverPayDistanceTable';

const DriverPayConfiguration = () => {


  const [userData, setuserData] = useContext(ContextData);
  const [isEditDisable,setEditDisable]=useState(false);
  const [ tabName,setTabName]=useState("driverPayByTerminal")
  useEffect(async () => {
    checkAccess()
  }, [userData]);

  const handletabSelectedDriverPay=(ChildData)=>{
    console.log("dhasddhiakld",ChildData)
    setTabName(ChildData)
  }

  const checkAccess = () => {
    const permission = userData?.roles?.rolespermission;
  
    permission?.map((permit) => {
      if (
        permit?.permissiondetail.code === "DriverpayByTerminal" &&
        permit?.can_edit === false
      ) {
        setEditDisable(true);
      }
    });
  };

    const [driverPayConfigClicked, setDriverPayConfigCliked] = useState(false);
    return (
        <div id='wrapper'>
            <Header
                userclicked={driverPayConfigClicked}
            // parentcallback={handelcallbackFromHeader}
            ></Header>
            <AppBar />
            <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            {!driverPayConfigClicked?
            <>
            <DriverPayConfigHeader isEditDisable={isEditDisable} parenCallback={handletabSelectedDriverPay}/>
            {
                tabName=="driverPayByTerminal" ?
               <DriverPayConfigTable isEditDisable={isEditDisable}/>
               : tabName=="driverPayException" ? 
               <DriverPayEXceptionTable />
               : tabName=="driverPayOverrides" ? 
               <DriverPayOverrideTable />
               :tabName=="driverPayDistances" ?
               <DriverPayDistanceTable /> :""
            }
          
           
            </>
           :"" }
          </div>
        </div>
      </div>
           
        </div>
    )
}

export default DriverPayConfiguration;
