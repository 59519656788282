import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/header";
import AppBar from "../../components/appbar";
import DriverHeader from "../../components/driverHeader/driverHeader";
import DriverTable from "../../components/driverTable/driverTable";
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import DriverByIdHeader from "../../components/driverByIdHeader/driverByIdHeader";
import DriverBodyForDetails from "../../components/driverBodyForDetails/driverBodyForDetails";
import {getAllDrivers, getDriver} from "../../services/driverService";
import DriverBodyForPreference from "../../components/driverBodyForPreference/driverBodyForPreference";
import DriverBodyForCertification from "../../components/driverBodyForCertification/driverBodyForCertification";
import DriverBodyForSchedule from "../../components/driverBodyForSchedule/driverBodyForSchedule";
import DriverBodyForScheduleModal from "../../components/driverBodyForScheduleModal/driverBodyForScheduleModal";
import {getTerminalByTerminalIds} from "../../services/terminalService";
import { ContextData } from "../../components/appsession";
import DriverBodyForDriverpay from "../../components/DriverBodyForDriverpay/DriverBodyForDriverpay";
import DriverAndTractoryHistory from "../../components/driverAndTractorHistory/driverAndTractorHistory";
import { getDriverOrTractorHistoryById } from "../../services/trailerService";
import { DateTime } from "luxon";

function Drivers(props) {
  const { id } = useParams();
  const [userData, setuserData] = useContext(ContextData);
  const [allDrivers, setallDriver] = useState([]);
  const [driverEx, setDriverEx] = useState([]);
  const [driverClicked, setdriverClicked] = useState(props.location?.state?.isDriverclicked?props.location.state.isDriverclicked : false);
  const [driverById, setdriverById] = useState({});
  const [headerTabName, setheaderTabName] = useState("details");
  const [allDriversDropdown, setAllDriversDropdown] = useState([]);
  const [terminalsOptions, setTerminalsOptions] = useState([]);
  const [regionOptions, setregionOptions] = useState([]);
  const [driverlistData, setdriverlistData] = useState([]);
  const [alloverrideSchedule, setalloverrideSchedule] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState();
  const [driverHistory, setDriverHistory] = useState([]);

  const history = useHistory();

  const handelcallbackFromHeader = (childdata) => {
    setdriverClicked(childdata);
    setheaderTabName("details");
    fetchAllDrivers();
  };

  const formatDate = (epoch_date, tzone) => {
    if (!tzone || tzone === "Eastern Standard Time") {
      tzone = "America/New_York"
    }
    if (tzone === "Central Standard Time") {
      tzone = "America/Chicago"
    }
    if (epoch_date > 0) {
      return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
    }
    else {
      return "No Data"
    }
  }

  const handelcallback = async (childdata, driver) => {
    if (driver.driver_id) {
      let newData = [];
      setIsLoading(true);

      const payload = {
        driver_id : driver?.driver_id
      };

      let response = await getDriverOrTractorHistoryById(payload);

      for (let i = 0; i < response.length; i++) {
        let temp = response[i];
        temp.planned_arrive_utc = formatDate(temp.planned_arrive_utc, temp.location_timezone);
        temp.planned_depart_utc = formatDate(temp.planned_depart_utc, temp.location_timezone);
        temp.actual_arrival_utc = formatDate(temp.actual_arrival_utc, temp.location_timezone);
        temp.actual_departure_utc = formatDate(temp.actual_departure_utc, temp.location_timezone)
        newData.push(temp);
      };

      setDriverHistory(newData);
      setIsLoading(false);
    }

    setdriverById(driver);
    setdriverClicked(childdata);
  };

  const handelcallbackFromLocationHeader = (childdata) => {
    console.log(childdata)
    setheaderTabName(childdata);
  };

  const checkAccess = () => {
    const permission = userData?.roles?.rolespermission.find(permission => permission?.permissiondetail.code === "Drivers")
    setIsDisabled(permission?.can_edit)
  }
  useEffect(()=>{
    checkAccess()
  },[allDrivers])

  const fetchAllDrivers = async () => {
    setIsLoading(true);
    const planning_terminal_ids = userData.planningprofiles?.map((it) => it.userPlanningprofile?.terminal_id);

    if (planning_terminal_ids?.length > 0) {
      let terminalinformationlist = await getTerminalByTerminalIds(
        planning_terminal_ids
      );

      let filterData = {
        terminalId: planning_terminal_ids,
      };
      setTerminalsOptions(terminalinformationlist);

      const driversresponse = await getAllDrivers(filterData)
      setallDriver(driversresponse);
      setdriverlistData(driversresponse);
      setIsLoading(false);
    }
    else {
      setIsLoading(false);
    }
  }

  useEffect(async () => {
    await fetchAllDrivers();
  }, [userData]);

  const parentCallBackForTerminalFilterDriver = (childData) => {
    if (childData.length > 0) {
      const tempallDrivers = [...driverlistData];
      const tempArray = [];
      tempallDrivers.map((el) => {
        if (childData.indexOf(el.terminal?.terminal_id) > -1) {
          tempArray.push(el);
        }
      });
      setallDriver(tempArray);
    } else {
      setallDriver(driverlistData);
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      const d = getDriver(id)
        .then(function (tractor) {
          // settractorById(tractor);
          handelcallback(true, tractor);
        })
        .catch(function (err) {
          NotificationManager.error(err, "Error");
        });
    }
  }, [id]);
  
  useEffect(() => {
    if(props.location?.state?.isDriverclicked)
    {
      const filterDriver = allDrivers.filter((e)=>e.driver_id === props.location?.state?.driver)
      if(filterDriver[0])
      {
        setdriverById(filterDriver[0])
      }
    }
  
   
  }, [allDrivers.length > 0])

const parencallbackoverrideschedule=(data)=>{
  setalloverrideSchedule(data);
}
  return (
    <div id="wrapper">
      <Header
        userclicked={driverClicked}
        parentcallback={handelcallbackFromHeader}
      ></Header>
      <AppBar></AppBar>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            {!driverClicked ? (
              <>
                <DriverHeader
                  allDriversDropdown={allDriversDropdown}
                  allDrivers={allDrivers}
                  regionOptions={regionOptions}
                  terminalsOptions={terminalsOptions}
                  parentCallBackForTerminalFilterDriver={
                    parentCallBackForTerminalFilterDriver
                  }
                />
                <DriverTable
                  allDrivers={allDrivers}
                  driverlistData={driverlistData}
                  setallDriver={setallDriver}
                  isLoading = {isLoading}
                  driverEx={driverEx}
                  parentcallback={handelcallback}
                />
              </>
            ) : (
              <>
                <DriverByIdHeader
                  driverById={driverById}
                  parentcallback={handelcallbackFromLocationHeader}
                />
               
                {headerTabName === "details" ? (
                  <DriverBodyForDetails driver={driverById} />
                ) : headerTabName === "certifications" ? (
                  <DriverBodyForCertification driver={driverById} />
                ) : headerTabName === "preference" ? (
                  <DriverBodyForPreference 
                    driver={driverById}                   
                    accessDisabled={isDisabled}
                  />
                ) : headerTabName === "driverpay" ? (
                  <DriverBodyForDriverpay
                    driver={driverById}                   
                    accessDisabled={isDisabled}
                  />
                 ): headerTabName === "shedule" ? (
                  <DriverBodyForSchedule 
                    driver={driverById}                   
                    accessDisabled={isDisabled}
                  />
                ): headerTabName === "history" ? (
                  <DriverAndTractoryHistory   
                    history={driverHistory}
                    isLoading={isLoading}
                    historyof={'driver'}
                  />
                ) : (
                  <DriverBodyForScheduleModal driver={driverById}
                  parencallbackoverrideschedule={parencallbackoverrideschedule}
                  />
                )}
              </>
            )}
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Drivers;
