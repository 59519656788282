import React, { useState, useEffect, useContext } from "react";
import { ContextData } from "../../components/appsession";
import Header from "../../components/header";
import AppBar from "../../components/appbar";
import YardcheackHeader from "../../components/yardcheckHeader/yardcheackHeader";
import YardCheckTable from "../../components/yardCheckTable/yardCheckTable";
import {getbyyards} from "../../services/yardService";
import YardCheckList from "../../components/yardCheckList/yardCheckList";
import YardCheckListHeader from "../../components/yardCheckListHeader/yardCheckListHeader";
import {getTerminalByIds} from "../../services/terminalService";
import { getAllTrailersbyTerminalID } from "../../services/trailerService";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import { toast } from "react-toastify";

const yardCheck = () => {
	const [userData, setuserData] = useContext(ContextData);
	const [allTrailers, setallTrailer] = useState([]);
	const [allTerminal, setallTerminal] = useState([]);
	const [terminalById, setterminalById] = useState({});
	const [isyardClicked, setisyardClicked] = useState(false);
	const [is_reload, setisReload] = useState(false);
	const [allUserTerminals, setallUserTerminals] = useState([]);
	const [isUserTerminalsLoaded, setIsUserTerminalsLoaded] = useState(true);
	const [currentTerminalCount, setcurrentTerminalCount] = useState(0);
	const [isDisabled, setIsDisabled] = useState(false);
	const [filteredTerminal, setFilteredTerminal] = useState([]);
	const [yardCheckLength, setYardCheckLength] = useState(0);
	const [hamburgerMenuClicked, sethamburgerMenuClicked] = useState(false);
	const [terminalsOptions, setTerminalsOptions] = useState([]);
	// const [selectedDateForFilter, setSelectedDateForFilter] = useState(null)

	const handelcallback = (childdata, terminal) => {
		setterminalById(terminal);
		setisyardClicked(childdata);
	};
	const handelcallbackFromHeader = async (childdata) => {
		setisyardClicked(childdata);
		// await getYards()
	};

	const handleYardLength = (length) => {
		setYardCheckLength(length);
	};

	const updateFromChild = async (updatedList) => {
		setisReload(!is_reload);
		try {
			let closedTerminalState = terminalById;
			closedTerminalState.endTs = Date.now();
			closedTerminalState.status = "close";
			// closedTerminalState.timezone = terminalsOptions[0].timezone;
			setterminalById(closedTerminalState);
		} catch(err){
			if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
			  errorMessageDisplay(err?.response?.data?.errors);
			}else{
			toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false } );
			}
			window.location.reload();
		}
	};

	const updatecountfromChild = async (count) => {
		setcurrentTerminalCount(count);
	};

	useEffect(async () => {
		try{
        let filterData = {
            terminal_id: [terminalById?.terminalid],
        };
        const trailers = await getAllTrailersbyTerminalID(filterData);
        setallTrailer(trailers);
	}catch(err){
		if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
		  errorMessageDisplay(err?.response?.data?.errors);
		}else{
		toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false } );
		}
	  }
    }, []);

	useEffect(async () => {
			try {
				const userterminalid = userData.planningprofiles?.map(it => it.userPlanningprofile?.terminal_id);
				if (userterminalid?.length > 0) {
					const terminalRes = await getTerminalByIds(userterminalid);
					setTerminalsOptions(prevTerminal => [...prevTerminal, ...terminalRes]);
	
					const terminals = await getbyyards(userterminalid);
					setallTerminal(terminals);
				}
			} catch (err) {
				if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
					errorMessageDisplay(err?.response?.data?.errors);
				} else {
					toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false });
				}
			}
	}, [userData.terminals, is_reload]);

	const handelcallbackForYard = (childData, date) => {
		// setSelectedDateForFilter(date)

		let terminalId = childData.map((el) => el.substring(0, 3));

		setFilteredTerminal(terminalId);
	};
	const sethamburgerMenuToggle = (data) => {
		sethamburgerMenuClicked(data);
	};

	return (
		<div>
			<div id="wrapper">
				<Header
					userclicked={isyardClicked}
					parentcallback={handelcallbackFromHeader}
					sethamburgerMenuToggle={sethamburgerMenuToggle}
				></Header>
				<AppBar hamburgerMenuClicked={hamburgerMenuClicked}></AppBar>
				<div className="content-page-tankwash">
					<div className="content">
						<div className="container-fluid">
							{!isyardClicked ? (
								<>
									<YardcheackHeader
										key={currentTerminalCount}
										currentTerminalCount={currentTerminalCount}
										// parentCallBackForYardFilter={parentCallBackForYardFilter}
										parentCallback={handelcallbackForYard}
										terminalsOptions={terminalsOptions}
										yardCheckLength={yardCheckLength}
										/>
									<YardCheckTable
										allUserTerminals={allUserTerminals}
										terminalsOptions={terminalsOptions}
										notifyParentCount={updatecountfromChild}
										allTerminal={allTerminal}
										isUserTerminalsLoaded={isUserTerminalsLoaded}
										parentcallback={handelcallback}
										setallTerminal={setallTerminal}
										accessDisabled={isDisabled}
										filteredTerminal={filteredTerminal}
										// selectedDateForFilter={selectedDateForFilter}
										parentYardLength={handleYardLength}
										currentTerminalCount={currentTerminalCount}
										yardCheckLength={yardCheckLength}
									/>
								</>
							) : (
								<>
									<YardCheckListHeader terminalById={terminalById} allTrailers={allTrailers} />
									<YardCheckList
										key={terminalById.id}
										terminalById={terminalById}
										parentcallback={updateFromChild}
										accessDisabled={isDisabled}
										allTrailers={allTrailers}
										setallTrailer={setallTrailer}
									/>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default yardCheck;
