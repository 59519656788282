import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/header";
import AppBar from "../../components/appbar";
import TankwashcodeHeader from "../../components/tankwashcodeHeader/tankwashcodeHeader";
import Tankwashcodetable from "../../components/tankwashcodetable/tankwashcodetable";
import {getAllWashprocedures} from "../../services/tankWashService";
import { ContextData } from "../../components/appsession";
import errorMessageDisplay from "../../utils/errorMessageDisplay";
import { toast } from "react-toastify";

function Tankwashcode(props) {
  const [tankwashClicked, settankwashClicked] = useState(false);
  const [tankwashById, settankwashById] = useState({});
  const [descriptionOptions, sedescriptionOptions] = useState([]);
  const [userData, setuserData] = useContext(ContextData);
  const [isDisabled, setIsDisabled] = useState(false);

  const handelcallback = (childdata, tankwashdata) => {
    settankwashById(tankwashdata);
    settankwashClicked(childdata);
  };
  useEffect(async () => {
    try{
    const alldescription = await getAllWashprocedures();
    sedescriptionOptions(alldescription);
    } catch (err) {
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      } else {
        toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false });
      }
    }
  }, []);

  useEffect(async () => {
    checkAccess()
  }, [userData]);

  const checkAccess = () => {
    const permission = userData?.roles?.rolespermission;
    permission?.map((permit) => {
      if (
        permit?.permissiondetail.code === "WashTypes" &&
        permit?.can_edit === false
      ) {
        setIsDisabled(true);
      }
    });
  };

  return (
    <div id='wrapper'>
      <Header
        userclicked={tankwashClicked}
        //parentcallback={handelcallbackFromHeader}
      ></Header>

      <AppBar></AppBar>
      <div className='content-page'>
        <div className='content'>
          <div className='container-fluid'>
            {!tankwashClicked ? (
              <>
                <TankwashcodeHeader
                  parentcallback={handelcallback}
                  tankwashById={tankwashById}
                  accessDisabled={isDisabled}
                />
                <Tankwashcodetable descriptionOptions={descriptionOptions} accessDisabled={isDisabled} userData={userData} />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Tankwashcode;
