import { useState, useEffect, useContext,useCallback } from 'react';
import { Button as MUIButton, FormControl, Select, CircularProgress } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos, Restaurant } from "@mui/icons-material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { Grid as MGrid } from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from "@mui/lab";
import { ContextData } from "../appsession";
import { DropContext } from "../../pages/planner/planner";
import { updateSelectedPlanningProfileOfUser } from "../../services/userService";
import { DateTime } from "luxon";
import debounce from 'lodash/debounce';
import {changeTrailerLocation} from "../../services/planningBoardService";
import {
    NotificationContainer,
    NotificationManager,
  } from "react-notifications";
import Modal from "react-bootstrap/Modal";
import { Autocomplete } from "@mui/lab";
import Button from "react-bootstrap/Button";
import { TextField } from "@mui/material";
import { getLatestTrailerhistorybytrailerid } from '../../services/trailerService';
import { sendBroadcastMessage } from '../../services/messageService';
import { getDecodedURI, getEncodedURI } from '../../utils/urlEncodeandDecode';
import { useLocation,useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { genericSearch, getAllDataByGenericAPI } from '../../services/genericService';

const PlannerBoardTopSection = (props) => {
    const {
        profile,
        selectedTimezoneDate,
        selectedPlanningProfile,
        allPlanningProfile,
        setallPlanningProfile,
        usersPlanningProfile,
        setusersPlanningProfile,
        setselectedPlanningProfile,
        setSelectedTimezoneDate,
        setpalnningBoardLoading,
        palnningBoardLoading,
        setplannedAcctualToggle,
        plannedAcctualToggle,
        plannerTimezone,
        setPlannerTimezone,
        isEditPermission
    } = props;
console.log("plannerTimezone",plannerTimezone)
    const context = useContext(DropContext);
    const [userData, setuserData, getUserInfoMSGraph, userUniqueId] = useContext(ContextData);
    const [Profile, setProfile] = useState(profile);
    const [selectedPlanningProfileOptions, setselectedPlanningProfileOptions] = useState([]);
    const [unassignedPlanningProfileOptions, setUnassignedPlanningProfileOptions] = useState([]);
    const [assignedPlanningProfileOptions, setAssignedPlanningProfileOptions] = useState([]);
    const [assignedPlanningProfile, setAssignedPlanningProfile] = useState({});
    const [modalShow, setModalShow] = useState(false);
    const [allTrailers, setAllTrailers] = useState([]);
    const [selectedTrailer, setSelectedTrailer] = useState(null);
    const [trailerLocation, setTrailerLocation] = useState({ location_code: '', location_name: '' });
    const [allLocations, setAllLocations] = useState([]);
    const [selectedDestination, setselectedDestination] = useState(null);
    const [selectedplanneddate, setselectedplanneddate] = useState(null);
    const [trailersHistory, setTrailersHistory] = useState([]);
    const [selectedDate, setSelectedDate] = useState(DateTime.now().setZone(plannerTimezone).startOf("day").toMillis());
    const [destinationStop, setDestinationStop] = useState("DT");
    const [broadCastMessage,setBroadCastMessage]=useState("");
    const [broadcastModalOpen,setBroadcastModalOpen]=useState(false);   
    const [changeTrailerLocationCnfBtn, setChangeTrailerLocationCnfBtn] = useState(false);
    const [changeTrailerLocationBtn, setChangeTrailerLocationBtn] = useState(false);
    const [loadingLoacation, setLoadingLoacation] = useState(false);
    const location= useLocation();
    const history = useHistory();

    const planningProfileChangeHandler = async (e) => {
        let selectedValue = e.target.value;
        let selectPlanningProfile = allPlanningProfile.find((el) => el.terminal_id === selectedValue);
        localStorage.removeItem("userpreference");
        localStorage.removeItem('userpreferenceTrailerstab');

        setselectedPlanningProfile(selectPlanningProfile);
        setAssignedPlanningProfile(selectedValue);
        let params=getDecodedURI(location.search);
        params.planningProfile=selectedValue
        history.push({pathname:location.pathname,search:`${getEncodedURI(params)}`});
        let newTimezone = getTimeZone1(selectPlanningProfile.terminal.timezone);
        setPlannerTimezone(newTimezone);

        setSelectedTimezoneDate(DateTime.fromMillis(selectedTimezoneDate).setZone(plannerTimezone).setZone(newTimezone, { keepLocalTime: true }).toMillis());
        setpalnningBoardLoading(true);

        // Storing selected planning profile in user's table for user
        let payload = {
            id: userData.id,
            userpreferences: {
                currentlySelectedPlanningProfile: [selectPlanningProfile]
            }
        };

        if (userData.userpreferences) {
            if (userData.userpreferences.orderstabsequence) {
                payload.userpreferences.orderstabsequence = userData.userpreferences.orderstabsequence;
            }
            if (userData.userpreferences.trailertabsequence) {
                payload.userpreferences.trailertabsequence = userData.userpreferences.trailertabsequence;
            }
        }

        try {
            const updateOrdersResponse = await updateSelectedPlanningProfileOfUser(payload);
            await getUserInfoMSGraph(userUniqueId);
        }catch(err){
            if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
              errorMessageDisplay(err?.response?.data?.errors);
            }else{
            toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false } );
            }
          }
    };

    const handleDateChange = (date) => {
        setpalnningBoardLoading(true)
        setSelectedDate(DateTime.fromISO(date).setZone(plannerTimezone).startOf("day").toMillis())
        setSelectedTimezoneDate(DateTime.fromISO(date).setZone(plannerTimezone).startOf("day").toMillis());
        let params=getDecodedURI(location.search);
        params.selectedDate=DateTime.fromISO(date).setZone(plannerTimezone).startOf("day").toISO()?.split("T")[0];
        history.push({pathname:location.pathname,search:`${getEncodedURI(params)}`});
    };

    const prevDateHandler = (e, selectedDate) => {
        setpalnningBoardLoading(true)
        let dateTs = selectedDate;
        let prevDateJsdate = DateTime.fromMillis(dateTs).minus({days:1}).setZone(plannerTimezone).startOf("day").toMillis();
        setSelectedDate(prevDateJsdate)
        setSelectedTimezoneDate(prevDateJsdate);
        let params=getDecodedURI(location.search);
        params.selectedDate= DateTime.fromMillis(dateTs).minus({days:1}).setZone(plannerTimezone).startOf("day").toISO()?.split("T")[0]
        history.push({pathname:location.pathname,search:`${getEncodedURI(params)}`});
    };
    
    const nextDateHandler = (e, selectedDate) => {
        setpalnningBoardLoading(true)
        let dateTs = selectedDate;
        let nextDateJsdate = DateTime.fromMillis(dateTs).plus({days:1}).setZone(plannerTimezone).startOf("day").toMillis()
        setSelectedDate(nextDateJsdate)
        setSelectedTimezoneDate(nextDateJsdate)
        let params=getDecodedURI(location.search);
        params.selectedDate=DateTime.fromMillis(dateTs).plus({days:1}).setZone(plannerTimezone).startOf("day").toISO()?.split("T")[0]
        history.push({pathname:location.pathname,search:`${getEncodedURI(params)}`});
    };

    useEffect(() => {
        let obj = {
            label: selectedPlanningProfile?.name,
            value: selectedPlanningProfile?.terminal_id,
            timezone: selectedPlanningProfile?.terminal?.timezone,
        }
        setAssignedPlanningProfile(obj)

        setSelectedDate(selectedTimezoneDate)

    }, [selectedPlanningProfile.terminal_id, selectedTimezoneDate])

    const getTimeZone1 = (zone) => {
        let Timezone;
        if (zone === "Eastern Standard Time" || zone === "America/New_York") {
            Timezone = "America/New_York";
        }
        if (zone === "Central Standard Time" || zone === "America/Chicago") {
            Timezone = "America/Chicago";
        }
        return Timezone;
    };

    useEffect(() => {
        let assignedProfile = [...allPlanningProfile].filter((profile) =>
            usersPlanningProfile.map((assign) => assign.planningprofile_id)
                .includes(profile.id)
        );
        const unassignedProfile = allPlanningProfile.filter(el => {
            return !usersPlanningProfile.find(element => {
                return element.planningprofile_id === el.id;
            });
        });

        let allOfDisabledAssignedPlanningProfile = [];
        let allOfDisabledUnAssignedPlanningProfile = [];

        if (assignedProfile.length > 0) {
            allOfDisabledAssignedPlanningProfile = assignedProfile.filter((profile) => profile.terminal?.use_planning === false);
        }

        if (unassignedProfile.length > 0) {
            allOfDisabledUnAssignedPlanningProfile = unassignedProfile.filter((profile) => profile?.terminal?.use_planning === false);
        }

        if (assignedProfile.length === allOfDisabledAssignedPlanningProfile.length) {
            setAssignedPlanningProfileOptions([]);
        } else {
            let assigned = [];

            assignedProfile.map((select) => {
                let assignedProfileObj = {
                    label: select?.name,
                    value: select?.terminal_id,
                    timezone: select?.terminal?.timezone,
                    use_planning: select?.terminal?.use_planning
                };               

                assigned.push(assignedProfileObj);
            });
            
            setAssignedPlanningProfileOptions(assigned);
        }

        if (unassignedProfile.length === allOfDisabledUnAssignedPlanningProfile.length) {
            setUnassignedPlanningProfileOptions([]);
        } else {
            let unassigned = [];

            unassignedProfile.map((select) => {
                let assignedProfileObj = {
                    label: select?.name,
                    value: select?.terminal_id,
                    timezone: select?.terminal?.timezone,
                    use_planning: select?.terminal?.use_planning
                };
            
                unassigned.push(assignedProfileObj);
            });

            setUnassignedPlanningProfileOptions(unassigned);
        }
    }, [allPlanningProfile, usersPlanningProfile]);
  
    const handleopenchangeTrailerLocation= async()=>{
        setChangeTrailerLocationBtn(true)
        let trailers;
        try {
            trailers = await getAllDataByGenericAPI('vw_trailer_lookup',[]);
            if(trailers){
                setModalShow(true);
                setChangeTrailerLocationBtn(false);
                setAllTrailers(trailers);
                setselectedplanneddate(DateTime.now().setZone(selectedPlanningProfile?.terminal.timezone))
            }
        } catch(err){
            if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
              errorMessageDisplay(err?.response?.data?.errors);
            }else{
            toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false } );
            }
            setChangeTrailerLocationBtn(false);
          }
    }

    const handleModalShow = async () => {
    setModalShow(true);
    };

    const handleRefresh = async () => {
        setpalnningBoardLoading(true)
        let payloadforrefresh = {
            "date": selectedTimezoneDate,
            "terminals": [selectedPlanningProfile.terminal_id]
        }
        await context.refreshDataFromAPI(payloadforrefresh);

    };

    const handleDateChangetrailer = (date) => {
        setselectedplanneddate(date)
    };
    
    const getTimeZone = (zone) => {
        if (!zone || zone === "America/New_York" || zone === "Eastern Standard Time") {
            return ("EST")
        }
        if (zone === "America/Chicago" || zone === "Central Standard Time") {
            return "CDT"
        }
    }

    const handletoggleChange = async (newAlignment) => {
        if (newAlignment === null) return;
        setpalnningBoardLoading(true)
        setplannedAcctualToggle(newAlignment);
        context.setPlannedOrUnplanned('center')
        let params=getDecodedURI(location.search);
        if (newAlignment !== "left") {
            params.mode="actual"
            history.push({pathname:location.pathname,search:`${getEncodedURI(params)}`});
        } else {
            params.mode="planned"
            history.push({pathname:location.pathname,search:`${getEncodedURI(params)}`});
        }
       
        let payloadforrefresh = {
            "date": selectedTimezoneDate,
            "terminals": [selectedPlanningProfile.terminal_id],
            "selectedTab": newAlignment
        };
        await context.refreshDataFromAPI(payloadforrefresh);
    };

    const handleTrailerChange = async (e, value) => {
        setSelectedTrailer(value);
         try{
        if (value) {
            const trailerHistory = await getLatestTrailerhistorybytrailerid(value?.trailer_id);
            if (Object.keys(trailerHistory).length > 0) {
                setTrailersHistory(trailerHistory);
                setTrailerLocation({
                    location_code: trailerHistory[0]?.location_code || '',
                    location_name: trailerHistory[0]?.location_name? trailerHistory[0]?.location_name : trailerHistory[0]?.name || "",
                });
            }
        } else {
            setTrailersHistory([]);
            setTrailerLocation({ location_code: '', location_name: '' });
        }
    }catch(err){
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          errorMessageDisplay(err?.response?.data?.errors);
        }else{
        toast.error('Something Went Wrong,while fetching trailer history, Please Refresh Your Screen', { autoClose: false } );
        }
        setTrailerLocation({ location_code: '', location_name: '' });
        setTrailersHistory([]);
    }
    };

    const debouncedHandleLocationChange = useCallback(
        debounce(async (newValue) => {
            try {
                setAllLocations([])
                setLoadingLoacation(true);
                let payload = newValue !== '' ? [{ code: newValue, operator: "contains" }, { location_name: newValue, condition: "OR", operator: "contains" }] : []
                const allLocData = await genericSearch("locations", payload);
                setAllLocations(allLocData?.data);
                setLoadingLoacation(false);
            } catch (error) {
                toast.error("Error fetching Locations:");
                setLoadingLoacation(false);
            }

        }, 1000),
        []
    );

    const handleLocationChange = (newValue) => {
        debouncedHandleLocationChange(newValue);
    };
   const handleLocationSelect =(newValue) =>{
    setselectedDestination(newValue);
   }

    const createOnClick = async () => {
        setChangeTrailerLocationCnfBtn(true);
        let lastTrailerLocation = trailersHistory?.filter((e) => e?.planned_arrive_utc)[0];
        let payload = {
            from_location_id: lastTrailerLocation?.location_code,
            to_location_id: selectedDestination?.code,
            trailer_id: lastTrailerLocation?.trailer_id,
            planned_date_time: selectedplanneddate.toISO(),
            terminal_id: selectedPlanningProfile.terminal_id
        }

        try {

            let response = await changeTrailerLocation(payload);
            if (response?.data?.status == 'failure' && response?.data?.errors && response?.data?.errors?.length > 0) {
                errorMessageDisplay(response?.data?.errors);
                setChangeTrailerLocationCnfBtn(false);
                NotificationManager.error(response?.data?.errors[0]?.message, "Error");
            } else {
                setModalShow(false)
                resetModalFields();
                setChangeTrailerLocationCnfBtn(false);
                setAllLocations([])
                NotificationManager.success("Trailer location changed successfully, creating stops and moves.", "Success");
            }
        } catch(err){
            if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
              errorMessageDisplay(err?.response?.data?.errors);
            }else{
            toast.error('Something went wrong while changing trailer location', { autoClose: false } );
            }
            setChangeTrailerLocationCnfBtn(false);
        }
    };

    const resetModalFields = () => {
        setSelectedTrailer(null);
        setTrailerLocation({ location_code: '', location_name: '' });
        setselectedDestination(null);
        setselectedplanneddate(null);
        setAllLocations([]);
        setLoadingLoacation(false);
    };
    const broadcastMessageHandler=async()=>{
        try {
            let payload={
                terminalId:selectedPlanningProfile.terminal_id,
                messageText:broadCastMessage,
                source:"BPA"
            }
    
            await sendBroadcastMessage(payload);
            setBroadcastModalOpen(false);
            setBroadCastMessage("")
            NotificationManager.success("Message Sent!",'success')
        }catch(err){
            if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
              errorMessageDisplay(err?.response?.data?.errors);
            }else{
            toast.error('Message could not be sent! Please Refresh Your Screen', { autoClose: false } );
            }
          }   
    }

    return (
        <>
            <div className='planning_board_topdate'>
                <div className="planningboard_driver_header">
                    <div className='multiselect w-25  pr terminal_drop_fixed'>
                        <div className='dropdownadjustPlanner'>
                            <FormControl className="dropDownStyle" variant="outlined">
                                <Select native id="grouped-native-select"
                                    onChange={(e) => { planningProfileChangeHandler(e) }}
                                    value={assignedPlanningProfile?.value}
                                    displayEmpty={true}
                                >
                                    {
                                        assignedPlanningProfileOptions.length > 0 ? (
                                            <optgroup className="main_block" label="Assigned Planning Profiles">
                                                {
                                                    assignedPlanningProfileOptions.map((item, index) => (
                                                        <option
                                                            className={!item?.use_planning ? 'inner_block disable_item' : 'inner_block'}
                                                            key={index}
                                                            value={item?.value}
                                                            disabled={!item?.use_planning}
                                                        >
                                                            {item.label}
                                                        </option>
                                                    ))
                                                }
                                            </optgroup>) : null

                                    }
                                    {
                                        unassignedPlanningProfileOptions.length > 0 ? (
                                            <optgroup className="main_block" label="Unssigned Planning Profiles">
                                                {
                                                    unassignedPlanningProfileOptions.map((item, index) => {
                                                        return (
                                                            <option
                                                                className={!item?.use_planning ? 'inner_block disable_item' : 'inner_block'}
                                                                key={index}
                                                                value={item?.value}
                                                                disabled={!item?.use_planning}
                                                            >
                                                                {item.label}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </optgroup>) : null

                                    }
                                </Select>
                            </FormControl>
                        </div>

                    </div>
               


                <div className='planning_board_topdate_inner text-center'>
                    <div className='planner_topdate_text'>
                        <div className="planner_board_top_arrow">
                            <ArrowBackIosNew onClick={(e) => prevDateHandler(e, selectedDate)} />
                        </div>
                        <div className="MuiFormControl-root MuiTextField-root css-i44wyl">
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DatePicker
                                    disableToolbar
                                    inputVariant="outlined"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    value={DateTime.fromMillis(selectedDate).setZone(plannerTimezone).startOf("day")}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="planner_board_top_arrow">
                            <ArrowForwardIos onClick={(e) => nextDateHandler(e, selectedDate)} />
                        </div>
                    </div>
                    <div className='planner_top_date'>
                        <div className='planner_dates_text2'>
                            {getTimeZone(assignedPlanningProfile.timezone)}
                        </div>
                    </div>
                </div>

                <div className='planning_board_button_container'>
                <div className="planning_board_refresh_button">
                        <button type="button"
                            className="btn_blue btn-blue mb_0 ex-pad access_save pd_rl changeLocationButton"
                            onClick={()=>{
                                setBroadcastModalOpen(true)
                            }}
                            // disabled={!isEditPermission}
                        >Broadcast</button>
                    </div>
                    <div className="planning_board_refresh_button">
                        <button type="button"
                            className="btn_blue btn-blue mb_0 access_save pd_rl changeLocationButton"
                            onClick={handleopenchangeTrailerLocation}
                            disabled={changeTrailerLocationBtn || !isEditPermission}
                        >Change Trailer Location</button>
                    </div>
                    <Modal
                        show={modalShow}
                        onHide={() => {
                            setModalShow(false);
                            resetModalFields();
                        }}
                        onShow={handleModalShow}
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter margin-top-bottom" className='text-bold'>
                                Change Trailer Location
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='meterial_autocomplete'>
                                <p >Select Trailer:</p>
                                
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={allTrailers}
                                    getOptionLabel={(option) => `${option.trailer_id}`}
                                    value={selectedTrailer}
                                    onChange={handleTrailerChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Choose a Trailer..."
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                            {selectedTrailer && (
                                <div className='meterial_autocomplete'>
                                    <p>Current Location:</p>
                                    <Autocomplete
                                        id="combo-box-locations"
                                        options={trailerLocation ? [trailerLocation] : []}
                                        getOptionLabel={(option) => `${option.location_code} - ${option.location_name}`}
                                        value={trailerLocation}
                                        onChange={(e, newValue) => setTrailerLocation(newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Current Location..."
                                                variant="outlined"
                                            />
                                        )}
                                        disabled={true}
                                    />
                                </div>
                            )}
                            {trailerLocation && (
                                <div className='meterial_autocomplete'>
                                    <p >Select a Destination:</p>
                                    <Autocomplete
                                        id="combo-box-locations"
                                        options={allLocations || []}
                                        getOptionLabel={(option) => option.code + " - " + option.location_name}
                                        value={selectedDestination}
                                        onChange={(event, newValue) => handleLocationSelect(newValue)}
                                        loading={loadingLoacation}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Select a Destination..."
                                                variant="outlined"
                                                onChange={(e) => handleLocationChange(e.target.value)} 
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                      <>
                                                        {loadingLoacation ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                      </>
                                                    ),
                                                  }}
                                            />
                                        )}
                                    />
                                </div>
                            )}
                            <div className='meterial_autocomplete'>
                                <p>Destination Stop Type:</p>
                                <Autocomplete
                                    id="combo-box-stop-type"
                                    options={[destinationStop]}
                                    getOptionLabel={(option) => option}
                                    value={destinationStop}
                                    disabled
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Stop Type"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </div>
                                <div className='meterial_autocomplete'>
                                    <p>Planned Date & Time</p>
                                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                                        <DateTimePicker
                                        slotProps={{
                                            textField: {
                                              error: false,
                                              fullWidth:true
                                            },
                                            actionBar: {actions: ['clear',"accept"]}
                                          }}
                                          orientation="landscape"
                                            value={selectedplanneddate}
                                            viewRenderers={{
                                                hours: renderTimeViewClock,
                                                minutes: renderTimeViewClock,
                                                seconds: renderTimeViewClock,
                                            }}
                                            onChange={handleDateChangetrailer}
                                        />
                                    </LocalizationProvider>
                                    </div>
                        </Modal.Body>
                         <Modal.Footer>
                                {
                                    !changeTrailerLocationCnfBtn ?
                                        <Button
                                            onClick={(i) =>
                                                createOnClick()
                                            }
                                             className="btn_blue btn-blue"
                                        >
                                            Change
                                        </Button> :
                                        <div className="spinner_modal_footer_md">
                                            <CircularProgress />
                                        </div>
                                }
                                <Button className="btn_blue btn-blue" onClick={() => {
                                setModalShow(false);
                                resetModalFields();
                                setChangeTrailerLocationBtn(false);
                                setAllLocations([]);
                                setLoadingLoacation(false);
                            }}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="planning_board_refresh_button">
                        <button type="button"
                            className="btn_blue btn-blue mb_0 access_save changeLocationButton"
                            onClick={handleRefresh}
                        >Refresh</button>
                    </div>
                    <MGrid
                        container
                        spacing={2}
                        direction='column'
                        alignItems='right'
                    >
                        <MGrid item>
                            <ToggleButtonGroup
                                
                                value={plannedAcctualToggle}
                                exclusive
                                onChange={(e)=>handletoggleChange(e.target.value)}
                                sx={{
                                    '& .MuiToggleButton-root': {
                                      backgroundColor: '#b53329',
                                      color: 'white',
                                      '&.Mui-selected': {
                                        backgroundColor: '#5eab5e',
                                        color: 'white',
                                        '&:hover': {
                                          backgroundColor: '#5eab5e',
                                          color: 'white',
                                        },
                                      },
                                      '&:hover': {
                                        backgroundColor: '#b53329',
                                        color: 'white',
                                      },
                                    },
                                  }}
                            >
                                <ToggleButton value='left'>PLANNED</ToggleButton>
                                <ToggleButton value='center'>ACTUAL</ToggleButton>
                            </ToggleButtonGroup>
                        </MGrid>
                    </MGrid>
                </div>


            </div>
        </div>
        <NotificationContainer/>
        <Modal
            aria-labelledby='contained-modal-title-vcenter'
            show={broadcastModalOpen}
            onHide={() => setBroadcastModalOpen(false)}
            centered 
            className="driver_chat_modal"
          >
          <Modal.Header closeButton className="message-modal-header">
                      <Modal.Title id='contained-modal-title-vcenter' >{`${selectedPlanningProfile.name}`}</Modal.Title>
                    </Modal.Header>
          <Modal.Body>
            
        
          
          </Modal.Body>
            <Modal.Footer>
            <div className="message_footer">
                <TextField
                  variant="outlined"
                  placeholder="Type a message" 
                  value={broadCastMessage}
                  onChange={(e)=>{setBroadCastMessage(e.target.value)}}
                />
                <Button variant="contained" className="btn btn-message" onClick={()=>{broadcastMessageHandler()}} >
                  Send
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

       
          </> 
    )
}

export default PlannerBoardTopSection;
