import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";
import App from "./App.js";
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import axios from 'axios';
import { msalConfig, loginRequest } from "./authConfig";
import jwt_decode from 'jwt-decode';


/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

const isTokenExpired = (token) => {
    if (!token) return true;
    const decodedToken = jwt_decode(token);
    const expirationTime = decodedToken.exp * 1000;
    const currentTime = Date.now();
    return expirationTime - currentTime < 1 * 60 * 1000;
};

axios.interceptors.request.use(
    async (response) => {
        const account = msalInstance.getAllAccounts()[0];
        let idToken = localStorage.getItem("idtoken");
        if (account && isTokenExpired(idToken)) {
            try {
                const msalResponse = await msalInstance.acquireTokenSilent({
                    ...loginRequest,
                    account: account,
                    forceRefresh: true
                });
                idToken = msalResponse.idToken;
                localStorage.setItem("idtoken", idToken);
                localStorage.setItem("accesstoken", msalResponse.accessToken);
            }
            catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    const msalResponse = await msalInstance.acquireTokenRedirect({
                        ...loginRequest,
                        account: account
                    });
                    idToken = msalResponse.idToken;
                    localStorage.setItem("idtoken", idToken);
                    localStorage.setItem("accesstoken", msalResponse.accessToken);
                } else {
                    console.error("Token acquisition failed", error);
                }
            }

        }

        response.headers.common.Authorization = `Bearer ${idToken}`;

        return response

    },
    (err) => {
        return Promise.reject(err);
    }
);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
