import React, { useState, useEffect, useContext } from "react";
import Search from "../../assets/images/Search-Button.svg";
import { Box, TablePagination } from "@mui/material";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ModalInput from "../../common/ModalInput";
import { ContextData } from "../../components/appsession";
import ProgressBar from "react-bootstrap/ProgressBar";
import { getCommodityGroupByID, searchCommodity } from "../../services/commoditygroupService";
import { CommodityGroupContext } from "../../pages/commoditygroup";
import {toast} from "react-toastify";
import errorMessageDisplay from "../../utils/errorMessageDisplay";

const CommoditygroupBodyForDetails = () => {

  // Contexts
  const [userData, setuserData] = useContext(ContextData);
  const commodityGroupContextData = useContext(CommodityGroupContext);


  // States
  const [commodities, setcommodities] = useState([]);
  const [searchData, setsearchData] = useState("");
  const [allDataAfterSearch, setallDataAfterSearch] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [commodityObj, setCommodityObj] = useState({}); 


  // page and count without search
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount]=useState(0);
  const [isSearched,setisSearched]=useState(false);
  const [isrefresh,setisrefresh]=useState(false);

  // page and count for search
  const [Searchpage, setSearchpagee] = useState(0);
  const [searchrowsPerPage, setsearchrowsPerPage] = useState(25);
  const [searchcount, setsearchcount]=useState(0);
  const [isSearchRefresh,setiseSearchrefresh]=useState(false)


  // Checking permissions
  const permission = userData?.roles?.rolespermission.find(commoditypermission =>
    commoditypermission?.permissiondetail.code === "CommodityGroups");

  // Sorting 
  let sortedCommodity = [...commodityGroupContextData.commodityloadunloadData]
  sortedCommodity.sort((a, b) => a.commoditygroupid.toUpperCase() < b.commoditygroupid.toUpperCase() ? -1 : 1);

  let DataAfterSearchSorted = [...allDataAfterSearch];
  DataAfterSearchSorted.sort((a, b) => a.commoditygroupid.toUpperCase() < b.commoditygroupid.toUpperCase() ? -1 : 1);

  
  // For getting numbers of data
  useEffect(async () => {
    setcommodities(commodityGroupContextData.commodityloadunloadData);
  }, [commodityGroupContextData.commodityloadunloadData]);

// this will fetch data on search
  const fetchSearchData=async()=>{
    if(!searchData){
      return;
    }
    try {
      commodityGroupContextData.setisDataLoaded(false)
      const payload = {
        "cg_id": commodityGroupContextData.commodityGroupById.code,
        "searchdata": searchData
      }
      const searchDataResponse = await searchCommodity(payload,Searchpage+1,searchrowsPerPage);   

      if (searchDataResponse.status === 200) {
        setallDataAfterSearch(searchDataResponse.data.data);   
        console.log(searchDataResponse.data.data, "deepu searchDataResponse.data.data")
        setisSearched(true);
        setsearchcount(searchDataResponse.data.countdata)
      } else {
        setallDataAfterSearch([]);
      }      
    } catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false } );
      }
      setallDataAfterSearch([]);
    }
    commodityGroupContextData.setisDataLoaded(true)
   }



  // For getting input
    const searchInputHandler = (value) => {
      setsearchData(value.toUpperCase());

      // Setting it to previous state when there is no search input
      if (!value) {
        setcommodities(commodityGroupContextData.commodityloadunloadData);
        setisSearched(false);
        setallDataAfterSearch([]);
      }
    };

  // It will run on clicking Enter button on input
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchHandler();
    }
  };  

  // this useEffecyt will run on data loading
  useEffect(async()=>{
    console.log("running on refresh")
    try {
      const payload2 = {
        "cg_id": commodityGroupContextData?.commoditycode
      };
  
      commodityGroupContextData.setisDataLoaded(false)
      const commodityGroupDetailsDataById = await getCommodityGroupByID(payload2, page+1, rowsPerPage);
      commodityGroupContextData.setisDataLoaded(true)
      if (commodityGroupDetailsDataById.status === 200) {     
        commodityGroupContextData.setcommodityloadunloaddata(commodityGroupDetailsDataById?.data.data);  
        console.log(commodityGroupDetailsDataById?.data.data)
        commodityGroupContextData.setisDataLoaded(true);
        setCount(commodityGroupDetailsDataById?.data?.count)
      }
    } catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Something Went Wrong, Please Refresh Your Screen', { autoClose: false } );
      }
    }
    
  },[commodityGroupContextData?.commoditycode,page,rowsPerPage,isrefresh,commodityGroupContextData.isRefresh])


  useEffect(()=>{
    if(searchData.length>0){
      fetchSearchData();
    }
  },[isSearchRefresh,Searchpage,searchrowsPerPage,isSearchRefresh])

  // Search handler
  const searchHandler = async () => {
    if(!searchData){
      return;
    }
    setSearchpagee(0);
    setsearchrowsPerPage(5)
    fetchSearchData();
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    let  rowperchangevaluerpage=e.target.value;
    if(rowperchangevaluerpage*page>count){
      setPage(Math.floor(count/rowperchangevaluerpage))
    }
    setRowsPerPage(parseInt(e.target.value, 10));
  };

  const handleSearchPageChange = (e, newPage) => {
    setSearchpagee(newPage);
  };

  const handlesearchRowsPerPageChange = (e) => {
    setsearchrowsPerPage(parseInt(e.target.value, 10));
  };

  const editModal = (commodity) => {
    setCommodityObj(commodity);
    setModalShow(true);
  };

  // getduration by loadtime in table
  function getDurationByDriverLoad(data, driverloadunload) {
    for (const item of data) {
      if (item.driverloadunload === driverloadunload) {
        return parseInt(item.duration, 10);
      }
    }
    return null;
  }

  return (
    <div className="col-xl-12">
      <div className="col-xl-12">
        <div className="card card_shadow">
          <div className="card-body special_card_padding">
            <h2 className="header-title"> </h2>
            <div className="search_area">
              <div className="search_left"></div>
              <div className="search_middle">
                <input
                  type="text"
                  placeholder="Search Commodity..."
                  className="special_searchbox"
                  onChange={(e) => searchInputHandler(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className="search_right">
                <img
                  src={Search}
                  className="search_button_icon"
                  onClick={searchHandler}
                />
              </div>
            </div>
          </div>

          <div className="table-responsive">
            {commodityGroupContextData.isDataLoaded ? (
              <table className="table table-borderless table-hover  table-centered m-0 special_fonts commodity_override">
                <thead className="table-light commodity_override_deep">
                  <tr>
                    <th>Action</th>
                    <th>Code</th>
                    <th>Name</th>
                    <th colSpan={4}>Load Time</th>
                    <th colSpan={2}>Unload Time</th>
                  </tr>
                </thead>
                <thead className="table-light ">
                  <tr>
                    <th>Edit</th>
                    <th></th>
                    <th></th>
                    <th>Yes</th>
                    <th>No</th>
                    <th>Tl</th>
                    <th>Nb</th>
                    <th>Yes</th>
                    <th>No</th>
                  </tr>
                </thead>
           
                  {isSearched ? (
                    DataAfterSearchSorted.map((commodity, index) => (
                      <tbody>
                       <tr key={index}>
                          <td>
                            {permission.can_edit ? (
                              <Button className="btn_blue_sm btn-blue ml_10" onClick={() => editModal(commodity)}>
                                Edit
                              </Button>
                            ) : (
                                <Button className="btn_blue_sm btn-blue ml_10"
                                  disabled
                                >
                                  Edit
                                </Button>
                            )}
                          </td>
                          <td>{commodity?.commodity_id}</td>
                          <td>{commodity?.description}</td>
                          {/* <td>{commoditygroup.loadtime}</td> */}
                          <td>
                            {getDurationByDriverLoad(commodity?.loadtime, "Y")}
                          </td>
                          <td>
                            {getDurationByDriverLoad(commodity?.loadtime, "N")}
                          </td>
                          <td>
                            {getDurationByDriverLoad(commodity?.loadtime, "TL")}
                          </td>
                          <td>
                            {getDurationByDriverLoad(commodity?.loadtime, "NB")}
                          </td>
                          <td>
                            {getDurationByDriverLoad(commodity?.unloadtime, "Y")}
                          </td>
                          <td>
                            {getDurationByDriverLoad(commodity?.unloadtime, "N")}
                          </td>
                          {/* <td>{commoditygroup.unloadtime}</td> */}
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    sortedCommodity.map((commodity, index)=>(
                      <tbody>
                      <tr key={index}>
                          <td>
                            {permission.can_edit ? (

                              <Button className="btn_blue_sm btn-blue ml_10"
                                onClick={() => editModal(commodity)}>
                                Edit
                              </Button>
                            ) : (
                                <Button className="btn_blue_sm btn-blue ml_10"
                                  disabled
                                >
                                  Edit
                                </Button>
                            )}
                          </td>
                          <td>{commodity?.commodity_id}</td>
                          <td>{commodity?.description}</td>                          
                          <td>
                            {getDurationByDriverLoad(commodity?.loadtime, "Y")}
                          </td>
                          <td>
                            {getDurationByDriverLoad(commodity?.loadtime, "N")}
                          </td>
                          <td>
                            {getDurationByDriverLoad(commodity?.loadtime, "TL")}
                          </td>
                          <td>
                            {getDurationByDriverLoad(commodity?.loadtime, "NB")}
                          </td>
                          <td>
                            {getDurationByDriverLoad(commodity?.unloadtime, "Y")}
                          </td>
                          <td>
                            {getDurationByDriverLoad(commodity?.unloadtime, "N")}
                          </td>
                        </tr>
                      </tbody>
                    ) )
                  )}
              </table>
            ) : (
              <div style={{ padding: "15px" }}>
                <ProgressBar animated now={100} />             
              </div>
            )}
            {/* Pagination functionalities */}
            {isSearched ? (
                <Box>
                  <TablePagination
                    rowsPerPageOptions={[25,50,100]}
                    component="div"
                    count={searchcount}
                    page={Searchpage}
                    rowsPerPage={searchrowsPerPage}
                    onPageChange={handleSearchPageChange}
                    onRowsPerPageChange={handlesearchRowsPerPageChange}
                  />
                </Box>
             ) : (
              <Box>
                <TablePagination
                  rowsPerPageOptions={[25,50,100]}
                  component="div"
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </Box>
            )}




             {/* This modal will open after clicking on Edit button */}
             <Modal
              show={modalShow}
              onHide={() => setModalShow(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              className="comodity_modal"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ModalInput
                  commodityObj={commodityObj}
                  type="commodity"
                  setModalShow={setModalShow} 
                  isSearched={isSearched}  
                  setiseSearchrefresh={setiseSearchrefresh}  
                  setisrefresh={setisrefresh}       
                />
              </Modal.Body>
            </Modal>


          </div>
        </div>
      </div>
    </div>
  );
};

export default CommoditygroupBodyForDetails;
