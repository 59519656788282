import React, { useState, useEffect, useContext } from "react";
import AppBar from "../../components/appbar";
import Header from "../../components/header";
import TankWashOrdersTable from "../../components/tankWashOrdersTable/tankWashOrdersTable";
import "@progress/kendo-theme-default/dist/all.css";
import { DateTime } from "luxon";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  getAlllocationsbyterminaliswash,
  getTrailerWashWorkOrders,
  updateTrailerWashWorkOrders,
  getWashDuration,
  getTankWashCode, 
} from "../../services/tankWashService";
import "./tankwash.css";
import TankwashlocationHeader from "../../components/tankwashlocationHeader/tankwashlocationHeader";
import TankwashdraggbleMainpage from "../../components/tankwashdraggbleMainpage/tankwashdraggbleMainpage";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useHistory } from "react-router-dom";
import errorMessageDisplay from "../../utils/errorMessageDisplay";

const tankWashDragContext = React.createContext();
export { tankWashDragContext };

const currentYear = new Date().getFullYear();

const parseAdjust = (eventDate) => {
  const date = new Date(eventDate);
  date.setFullYear(currentYear);
  return date;
};

export const SchedulerConfigContext = React.createContext({
  slotDuration: [60, (_) => { }],
  slotDivision: [1, (_) => { }],
});

const TankWash = (props) => {
  const [sampleDataWithResources, setSampleDataWithResources] = React.useState([]);
  const washDetails=props?.location?.state?.washDetails ? props?.location?.state?.washDetails:{location_code:"",trailer_id:""};
  const [selectedLocation, setselectedLocation] = useState();
  const [dragFrom, setDragFrom] = useState(null);
  const [dragData, setDragData] = React.useState(-1);
  const [dropData, setDropData] = React.useState(-1);
  const [isTankWashClicked, setIsTankWashClicked] = useState(false);
  const [hamburgerMenuClicked, sethamburgerMenuClicked] = useState(false);
  const [tabSelected, settabSelected] = useState("");
  const [calenderDrpdownClicked, setcalenderDrpdownClicked] = useState(false);
  const [tankwashLoading, setTankwashLoading] = useState(false);
  const [hourArr, sethourArr] = useState(Array.from(Array(24).keys()));
  const [dragset, setdragset] = useState(Array.from(Array(48).keys()));
  const [alllocationnamelist, setalllocationnamelist] = useState([]);
  
  const [selectedDate, setselectedDate] = useState(DateTime.now().toMillis());
  const [tankWashData, setTankWashData] = useState([]);
  const [hoursOfOperation, sethoursOfOperation] = useState([])
  const [datarefresh, setdatarefresh] = useState(false)
  const [orderClicked, setorderClicked] = useState(false)
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [washDeatilsById, setwashDeatilsById] = useState({})
  const [selectedValue, setSelectedValue] = useState("center");
  const [datarefeshorderdata, setdatarefeshorderdata] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [selectedTimezoneDate, setSelectedTimezoneDate] = useState();

  const handleCallback = (childData) => {
    setdatarefeshorderdata(childData);
  };

  const location = useLocation();
  console.log("🚀 ~ TankWash ~ location:", location);

  



  useEffect(() => {
    if (selectedLocation) {
      setTankwashLoading(true);
      const wash_location_id = selectedLocation?.code;  

      // This date is for fetching orders      
      let needed_date_utc = DateTime.fromMillis(selectedDate).setZone(selectedLocation.timezone).startOf("day").toMillis();
      setSelectedTimezoneDate(needed_date_utc);


      // Immediately Invoked Function Expression
      (async function () {      
        let response = await getTrailerWashWorkOrders({ needed_date_utc, wash_location_id });        
        setResponseData(response);
      })();
    }

  }, [selectedLocation, datarefresh, selectedDate]);


  useEffect(async () => {
    if (selectedLocation) {
      if (responseData.length > 0) {
        setTankwashLoading(false);
      } else {
        setTimeout(() => {
          setTankwashLoading(false);
        }, 2000)
      }

      const wash_bays = selectedLocation.no_of_wash_bays;
     
      if (selectedValue === "center") {
        setTankWashData(responseData.filter((item) => [null, undefined, false, ""].includes(item.is_scheduled)));
      } else if (selectedValue === "left") {
        setTankWashData(responseData.filter((item) => item.is_scheduled === true
          && (
            // DateTime.fromMillis(item?.end_time_utc, { zone: selectedLocation?.timezone }).startOf("day").equals(DateTime.fromMillis(selectedDate, { zone: selectedLocation?.timezone }).startOf("day"))
            // || DateTime.fromMillis(item?.start_time_utc, { zone: selectedLocation?.timezone }).startOf("day").equals(DateTime.fromMillis(selectedDate, { zone: selectedLocation?.timezone }).startOf("day"))
            (item?.end_time_utc <= selectedTimezoneDate + 24 * 60 * 60 * 1000) && (item?.start_time_utc >= selectedTimezoneDate)
          )
        ));
      } 

      const display_blocks = responseData.filter(
        (it) =>
          it.is_scheduled
          &&
          (it.start_time_utc ? true : false) &&
          (it.end_time_utc ? true : false) &&
          (
            // DateTime.fromMillis(it?.end_time_utc, { zone: selectedLocation?.timezone }).startOf("day").equals(DateTime.fromMillis(selectedDate, { zone: selectedLocation?.timezone }).startOf("day")) || DateTime.fromMillis(it?.start_time_utc, { zone: selectedLocation?.timezone }).startOf("day").equals(DateTime.fromMillis(selectedDate, { zone: selectedLocation?.timezone }).startOf("day"))
            (it?.end_time_utc <= (selectedTimezoneDate + 24 * 60 * 60 * 1000)) && (it?.start_time_utc >= (selectedTimezoneDate))
          )
      );

      setSampleDataWithResources(
        [...Array(wash_bays).keys()].map((it) => [
          ...display_blocks.map((dataItem, idx) => {
            if (it === dataItem.bay) {
              return {
                bay: it,
                index: idx,
                id: dataItem.wash_id,
                start: dataItem.start_time_utc,
                end: dataItem.end_time_utc,
                duration:
                  DateTime.fromJSDate(
                    parseAdjust(dataItem.end_time_utc)
                  ).toMillis() -
                  DateTime.fromJSDate(
                    parseAdjust(dataItem.start_time_utc)
                  ).toMillis(),
                title:
                  "Trailer " +
                  dataItem.trailer_id +
                  " | " +
                  dataItem.int_wash_code,
                int_wash_code: dataItem.int_wash_code,

                intwashcode: dataItem.int_wash_code,
                trailerid:
                  dataItem.trailer_id,
                in_date: dataItem.in_date_utc,
                out_date: dataItem.out_date_utc,
                needed_date: dataItem.needed_date_utc,
                is_scheduled: dataItem.is_scheduled,
                int_wash_description: dataItem.int_wash_description,
                isLoading: false
              };
            }

          }),
        ])
      );
    } else {
      setSampleDataWithResources([]);
      setTankWashData([]);
    }
  }, [selectedDate, selectedValue, selectedLocation, datarefresh, responseData]);

  useEffect(async () => {
    let sampleDataWithResourcesCopy = structuredClone(sampleDataWithResources);
    const customId = "custom-id-yes";

    try {
      if (dragFrom === "dataBar" && dragData.bay == dropData.bay) {
        const data = sampleDataWithResources[dragData.bay][dragData.index];
        
        const start_date =
          DateTime.fromMillis(selectedDate).setZone(selectedLocation?.timezone).startOf("day").toMillis() +
          Math.floor(dropData.index / (dragset.length / 24)) * 3600000 +
          (((dropData.index % (dragset.length / 24)) * 60) /
            (dragset.length / 24)) *
          60000;

          setSampleDataWithResources(
            await Promise.all(
              sampleDataWithResources.map(async (item, bay) => {
                if (bay === dropData.bay) {
                  return await Promise.all(
                    item.map(async (dataItem, idx) => {
                      if (idx === dragData.index) {
                        return {
                          ...data,
                          start: start_date,
                          end:
                            DateTime.fromMillis(start_date + data.duration) >=
                              DateTime.fromMillis(selectedDate).endOf("day")
                              ? DateTime.fromMillis(selectedDate)
                                .endOf("day")
                                .toMillis()
                              : start_date + data.duration,
                        };
                      }
                      return dataItem;
                    })
                  );
                } else {
                  return item;
                }
              })
            )
          );
        
        if ( dropData?.index >= hoursOfOperation[0] && dropData?.index <= Number(hoursOfOperation.slice(-1)) && start_date   ) {
         
          const updateResponse = await updateTrailerWashWorkOrders({
            id: data.id,
            start_time_utc: start_date,
            end_time_utc: start_date + data.duration,
            bay: dropData.bay,
            is_scheduled: true,
          });

          if (updateResponse?.status === 200) {
            toast.success("Trailer work order moved successfully", "Success"), {
              toastId: customId,
            };
          }
        } else {
          toast.error("Trailer work order cannot be moved", "Error"), {
            toastId: customId,
          };
           setdatarefresh(!datarefresh);
        setTankwashLoading(true);
         
        }
    

      } else if (dragFrom === "dataBar" && dragData.bay != dropData.bay) {
        const data = sampleDataWithResources[dragData.bay][dragData.index];
        const bayDataWithResources = sampleDataWithResources[
          dragData.bay
        ].filter((item, idx) => idx !== dragData.index);

        const start_date =
          DateTime.fromMillis(selectedDate).setZone(selectedLocation?.timezone).startOf("day").toMillis() +
          Math.floor(dropData.index / (dragset.length / 24)) * 3600000 +
          (((dropData.index % (dragset.length / 24)) * 60) /
            (dragset.length / 24)) *
          60000;

        setSampleDataWithResources(
          await Promise.all(
            sampleDataWithResources.map(async (item, bay) => {
              if (bay === dragData.bay) {
                return bayDataWithResources;
              }
              if (bay === dropData.bay) {
                return [
                  ...item,
                  {
                    ...data,
                    start: start_date,
                    end:
                      DateTime.fromMillis(start_date + data.duration) >=
                        DateTime.fromMillis(selectedDate).endOf("day")
                        ? DateTime.fromMillis(selectedDate)
                          .endOf("day")
                          .toMillis()
                        : start_date + data.duration,
                  },
                ];
              }
              return item;
            })
          )
        );
       
        if ( dropData?.index >= hoursOfOperation[0] && dropData?.index <= Number(hoursOfOperation.slice(-1)) && start_date && data.duration) {
          const updateResponse = await updateTrailerWashWorkOrders({
            id: data.id,
            start_time_utc: start_date,
            end_time_utc: start_date + data.duration,
            bay: dropData.bay,
            is_scheduled: true,
          });

          if (updateResponse?.status === 200) {
            toast.success("Trailer work order moved successfully", "Success"), {
              toastId: customId,
            };
          }
        } else {
          toast.error("Trailer work order cannot be moved", "Error"), {
            toastId: customId,
          };
          setdatarefresh(!datarefresh);
        setTankwashLoading(true);
        }
        
    
      } else if (
        dragFrom === "ordersTable" &&
        dragData.wash_location_id == selectedLocation.code
      ) {

        const start_date =
          DateTime.fromMillis(selectedDate).setZone(selectedLocation?.timezone).startOf("day").toMillis() +
          Math.floor(dropData.index / (dragset.length / 24)) * 3600000 +
          (((dropData.index % (dragset.length / 24)) * 60) /
            (dragset.length / 24)) *
          60000;

        const duration = await getWashDuration(dragData.wash_id);

        if (duration <= 0) {
          toast.error("unable to schedule a tank wash order due to an invalid wash duration.", "Error");
          return;
        }

        const end_time = start_date + duration * 60 * 1000;

        setSampleDataWithResources(
          sampleDataWithResources.map((item, idx) => {
            if (idx == dropData.bay)
              return [
                ...item,
                {
                  bay: dropData.bay,
                  id: dragData.wash_id,
                  start: start_date,


                  end:
                    DateTime.fromMillis(end_time) >=
                      DateTime.fromMillis(selectedDate).endOf("day")
                      ? DateTime.fromMillis(selectedDate)
                        .endOf("day")
                        .toMillis()
                      : end_time,
                  start_orig: start_date,
                  end_orig: end_time,
                  title:
                    "Trailer " +
                    dragData.trailer_id +
                    " | " +
                    dragData.int_wash_code,
                  description: dragData.int_wash_code,
                  int_wash_code: dragData.int_wash_code,

                  intwashcode: dragData.int_wash_code,
                  trailerid:
                    dragData.trailer_id,
                  in_date_utc: dragData.in_date_utc,
                  out_date_utc: dragData.out_date_utc,
                  needed_date_utc: dragData.needed_date_utc,

                  is_scheduled: dragData.is_scheduled,
                  int_wash_description: dragData.int_wash_description,
                  isLoading: true
                },
              ];
            else return item;
          })
        );

        if (dropData?.index >= hoursOfOperation[0] && dropData?.index <= Number(hoursOfOperation.slice(-1)) && start_date && end_time && dragData.wash_id) {
          const updateResponse = await updateTrailerWashWorkOrders({
            id: dragData.wash_id,
            start_time_utc: start_date,
            end_time_utc: end_time,
            bay: dropData.bay,
            is_scheduled: true,
          });

          if (updateResponse?.status === 200) {
            toast.success("Trailer work order moved successfully", "Success"), {
              toastId: customId,
            };
          }
        } else {
          toast.error("Trailer work order cannot be moved", "Error")
        }

        setdatarefresh(!datarefresh);
        setTankwashLoading(true);
        //const needed_date_utc = DateTime.fromJSDate(selectedDate).toMillis();

        // const start1 = DateTime.fromMillis(selectedDate).toMillis();
        // const needed_date_utc = DateTime.fromMillis(start1, { zone: "local" })
        //   .toUTC(0, { keepLocalTime: true })
        //   .startOf("day")
        //   // .toMillis();
        // const wash_location_id = selectedLocation.code;

        setTankWashData(responseData.filter((item) => !item.is_scheduled));
        setTankwashLoading(false);
      }
      // toast.info("Work order moved successfully");
    }catch(err){
      if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
        errorMessageDisplay(err?.response?.data?.errors);
      }else{
      toast.error('Error while updating work order during drag and drop', { autoClose: false } );
      }
    setSampleDataWithResources(sampleDataWithResourcesCopy);
    }

    setDragFrom(null);
    setDragData(-1);
    setDropData(-1);
  }, [dropData]);

  const handleToggle = (event, value) => {
    if (value) {
      setSelectedValue(value);
    }
  };

  const handelcallbackFromHeader = async (childdata) => {
    setIsTankWashClicked(childdata);
  };

  const handelcallbackOrders = async (childdata, order) => {
    // setdriverById(driver);
    const washdetail = await getTankWashCode(order.wash_id)
    setwashDeatilsById(washdetail[0])
    setorderClicked(childdata);
    setModalShowEdit(true)
  };


  const selectedDateInToMilliSeconds =
    DateTime.fromMillis(selectedDate);

  const calenderDropdownSelectHandler = (event) => {
    let test = DateTime.fromISO(event).toMillis()
    setselectedDate(DateTime.fromISO(event).toMillis());
    setcalenderDrpdownClicked(false);
    let weekday = DateTime.fromISO(event).weekdayLong.toLowerCase();

    if (selectedLocation) {
      genarateHosBlocKOfLocation(selectedLocation, weekday);
    }
  };

  const dateChangeThroughArrows = (event) => {
    setselectedDate(event);
    setcalenderDrpdownClicked(false);
    let weekday = DateTime.fromMillis(event).weekdayLong.toLowerCase();

    if (selectedLocation) {
      genarateHosBlocKOfLocation(selectedLocation, weekday);
    }
  };

  // For getting all tankwash locations
  useEffect(async () => {
    const locationlistdropdown = await getAlllocationsbyterminaliswash();
    const selectedLocation=locationlistdropdown.filter(item=>item.code==washDetails.location_code)[0]
    setselectedLocation(selectedLocation)
    setalllocationnamelist(locationlistdropdown);
  }, []);

  // It will run on changing tankwash location
  const tankwashbayhandeler = async (e, it) => {
    if (it) {
      setTankwashLoading(true);
      setselectedLocation(it);
      let weekday = DateTime.now().weekdayLong.toLowerCase();
      genarateHosBlocKOfLocation(it, weekday);
    } else {
      setResponseData([]);
    }
  };

  const genarateHosBlocKOfLocation = (location, weekday) => {
    if (location) {
      let a = weekday + '_open';
      let b = weekday + '_close';
      let arr = [];
      let open = location[a];
      let close = location[b];


      let openmin = Number(open?.split(":")[0] * 60) + Number(open?.split(":")[1])
      let closemin = Number(close?.split(":")[0] * 60) + Number(close?.split(":")[1])

      arr.push(openmin)
      arr.push(closemin)

      let startBlock = Math.floor(arr[0] / 30)
      let endBlock = Math.floor(arr[1] / 30)

      let range
      if (startBlock && endBlock) {
        range = [...Array(endBlock - startBlock).keys()].map(x => x + startBlock);
        let arrlen = range.length
        range[0] = (arr[0] / 30)
        //  range[arrlen]=(arr[1] / 30)
        if (!Number.isInteger(arr[1] / 30)) {
          range.push(arr[1] / 30)
        }
      }
      else {
        range = Array.from(Array(48).keys())
      }
      sethoursOfOperation(range)
    }
  }

  return (
    <tankWashDragContext.Provider
      value={{
        dragData: dragData,
        setDragData: setDragData,
        dropData: dropData,
        setDropData: setDropData,
        baseData: sampleDataWithResources,
        dragFrom: dragFrom,
        setDragFrom: setDragFrom,
        selectedDate: selectedDate,
        setdatarefresh: setdatarefresh,
        datarefresh: datarefresh
      }}
    >
      <>
        <ToastContainer
          position='top-right'
          theme='colored'
          autoClose={2500}
        />
        <div id='wrapper'>
          <Header
            userclicked={isTankWashClicked}
            parentcallback={handelcallbackFromHeader}
          ></Header>
          <AppBar hamburgerMenuClicked={hamburgerMenuClicked}></AppBar>
          <div
            className={`content-page-tankwash ${tabSelected != "" ? "tankwash_extra_bottom_space" : ""
              }`}
          >
            {/* <div className='content-page-tankwash '> */}
            <div className='container-fluid'>
              {!isTankWashClicked ? (
                <>
                  <TankwashlocationHeader
                    calenderDropdownSelectHandler={calenderDropdownSelectHandler}
                    dateChangeThroughArrows = {dateChangeThroughArrows}
                    selectedDate={selectedDate}
                    setcalenderDrpdownClicked={setcalenderDrpdownClicked}
                    calenderDrpdownClicked={calenderDrpdownClicked}
                    //parentcallback={handelcallback}
                    alllocationnamelist={alllocationnamelist}
                    tankwashbayhandeler={tankwashbayhandeler}
                  //parentcallback={handelcallback}
                   selectedLocation={selectedLocation}
                  />

                  <TankwashdraggbleMainpage
                    calenderDropdownSelectHandler={calenderDropdownSelectHandler}
                    dateChangeThroughArrows = {dateChangeThroughArrows}
                    selectedDate={selectedDate}
                    setcalenderDrpdownClicked={setcalenderDrpdownClicked}
                    selectedDateInToMilliSeconds={selectedDateInToMilliSeconds}
                    dragset={dragset}
                    hourArr={hourArr}
                    orderClicked={orderClicked}
                    setorderClicked={setorderClicked}
                    alllocationnamelist={alllocationnamelist}
                    hoursOfOperation={hoursOfOperation}
                    selectedLocation={selectedLocation}
                    settabSelected={settabSelected}
                    modalShowEdit={modalShowEdit}
                    setModalShowEdit={setModalShowEdit}
                    washDeatilsById={washDeatilsById}
                    tankwashLoading={tankwashLoading}
                    callback={handleCallback}
                    washDetails={washDetails}  
                    
                  />
                </>
              ) : (
                ""
              )}
            </div>

            <div className='bottom_tab_wrapper'>
              <div className='tab_button_section '>
                <button
                  className={`tab_button ${tabSelected === "orders" ? "active_tab" : ""
                    }`}
                  onClick={(e) => settabSelected("orders")}
                >
                  ORDERS
                </button>
              </div>
              {tankwashLoading || datarefeshorderdata ? (
                <div className='oredertab_loader'>
                  <ProgressBar animated now={100} />
                </div>
              ) : tabSelected === "" ? null : tabSelected === "orders" ? (
                <TankWashOrdersTable
                  parentcallback={handelcallbackOrders}
                  settabSelected={settabSelected}
                  data={tankWashData}
                  location={selectedLocation}
                  handleToggle={handleToggle}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}

                />
              ) : (
                <>
                  <div className="tab_no_data">
                    <p className="contactdata_color">No Data Found</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    </tankWashDragContext.Provider>
  );
};

export default TankWash;
